@import '../../../styles/utilities/variables.scss';
@import '../../../styles/utilities/mixins.scss';
@import '../../../styles/utilities/breakpoints.scss';

.sweepstakes-callout {
  background: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 24px 32px;
  // padding: 80px 32px 38px;
  border-radius: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.12);

  @include breakpoint(sm) {
    margin-bottom: 80px;
    margin-left: -15px;
  }

  @include breakpoint(md) {
    // padding: 115px 16px 42px;
    padding: 50px 16px;
    margin-top: 20px;
    border-radius: 2rem;
  }
  // &::after {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   border: 1px solid theme('modalIcons');
  //   pointer-events: none;
  //   box-shadow: 0px 8px 48px 0px rgba(238, 45, 36, 0.24);
  //   border-radius: 24px;

  //   @include breakpoint(md) {
  //     border-radius: 32px;
  //   }
  // }

  svg {
    position: absolute;
    top: -60px;
    z-index: 1;
    width: 65px;

    @include breakpoint(md) {
      width: 100px;
      top: -64px;
    }
  }

  h4 {
    margin-bottom: 0;
    text-align: center;
  }

  h5 {
    margin-bottom: 0;
    font-weight: $font-weight-semi;

    @include breakpoint(0, sm) {
      text-align: center;
    }

    @include breakpoint(sm, sm) {
      max-width: 500px;
      font-size: 1.1rem;
    }

    @include breakpoint(md, md) {
      font-size: 1.1rem;
    }

    @include breakpoint(xl, 0) {
      max-width: 520px;
    }

    span {
      color: theme('primary');
      font-weight: 700;
    }
  }

  .dont-miss-out-copy {
    max-width: 178px;
    // position: absolute;
    // left: 51%;
    // transform: translateX(-50%);
    // top: 52px;
    // padding: 0 32px;

    @include breakpoint(md) {
      top: 74px;
      max-width: 333px;
    }
  }

  .sweepstakes-callout_content {
    text-align: center;
    @include breakpoint(0, sm) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .sweepstakes-callout_title {
      text-align: center;
      justify-content: center;
      max-width: 730px;
      margin: auto;

      svg {
        @include breakpoint(md, 0) {
          display: none;
        }
      }

      h4 {
        font-size: 1.5rem;

        @include breakpoint(sm) {
          font-size: 2rem;
        }

        @include breakpoint(md) {
          font-size: 2.5rem;
        }
      }

      .link {
        color: theme('link');
      }

      .message {
        font-size: 1rem;
        font-weight: 700;
        margin-top: 16px;

        @include breakpoint(sm) {
          font-size: 1.5rem;
          margin-top: 25px;
        }
      }
    }
    .link.bold {
      color: theme('default');
    }
  }
}
