.rules {
  padding: 4em 16px;

  @include breakpoint(1280, 1879.79) {
    max-width: calc(100vw - 648px);
  }

  h4 {
    text-align: center;
    margin-bottom: 32px;
  }

  ol {
    margin-block-start: 0em;
    margin-block-end: 1em;
    padding-inline-start: 16px;

    li::marker {
      font-family: $albra;
      font-size: 14px;
    }
  }

  p {
    text-align: left;
  }

  .underline {
    text-decoration: underline;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .italic {
    font-style: italic;
  }

  table {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }

  table,
  tr,
  td,
  th {
    border: 1px solid black;
  }

  th {
    vertical-align: middle;
  }

  td:empty:after {
    content: '\00a0'; /* HTML entity of &nbsp; */
  }

  .table_heading {
    text-align: center;
  }
}
