.banner-bottom {
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 1051;

  &.banner-bottom-mobile-logged-in {
    bottom: 66px;
  }

  @media (max-width: 1279.98px) {
    transform: translateX(-160px) translateY(-100%) translate3d(0, 100%, 0);
  }

  @media (min-width: 1280px) {
    transform: translateX(-50%) translateY(-100%) translate3d(0, 100%, 0);
  }

  #_fw_ad_container_html_banner_ad_ {
    display: flex !important;
  }

  &.fadeOutDown {
    animation-iteration-count: 1;
    animation-duration: 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-duration: 1s;
  }
}

.banner-right {
  display: none;

  @media (min-width: 1280px) {
    display: block;
    position: absolute;
    right: 0;
    z-index: 1051;

    &.banner-right-no-nav {
      top: 24px;
    }

    &.banner-right-with-nav {
      top: calc(70px + 124px);
    }
  }

  &.banner-right-garden {
    @media (min-width: 1280px) {
      top: 140px;
    }
  }

  &.banner-right-landing-page {
    display: none;

    @media (min-width: 1820px) {
      display: block;
    }
  }

  &.banner-right-form-page {
    display: none;

    @media (min-width: 1620px) {
      display: block;
    }
  }
}

.banner-footer {
  width: 100%;
  text-align: center;
  background-color: #333;
}

.ad-fade-in {
  animation: fadeIn 2s ease-in-out forwards;
}

.ad-fade-out {
  animation: fadeOut 1s ease-in-out forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
