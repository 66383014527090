html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: $albra;
  overflow-x: hidden;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#page-container {
  flex-grow: 1;
  @media (max-width: $bp-sm-max) {
    padding-top: 16px;
  }
}

#content-wrap {
  background-color: #fffbfa;
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

#footer {
  flex-shrink: 0;
  z-index: 20;
}

.page-background {
  position: relative;
  flex: 1;
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  text-align: center;

  .jm-logo {
    position: absolute;
    top: 6.25rem;
    width: 20rem;
    height: 20rem;
    z-index: 1;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 105.6%;

      text-align: center;
      letter-spacing: 0.07em;
      text-transform: uppercase;
    }

    @media only screen and (max-width: 1400px) {
      display: none;
    }
  }

  &.page-background-landing {
    .jm-logo {
      left: 6rem;
    }
  }

  &.page-background-game {
    background-image: none;
  }

  .top-background {
    position: absolute;
    left: 50%;
    height: auto;
    transform: translateX(-50%);

    &.top-background-landing {
      background-image: url('../../images/loveuary2024/background-landing-mobile.webp');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 270px;
      width: 100%;

      @include breakpoint(md) {
        background-image: url('../../images/loveuary2024/background-landing.webp');
        height: 520px;
      }

      &.top-background-logged-in {
        top: 140px;

        @include breakpoint(md) {
          top: 0px;
        }
      }
    }

    &.top-background-game {
      background-image: url('../../images/loveuary2024/background-game.webp');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 190px;
      width: 100%;
      top: 141px;

      @include breakpoint(md) {
        top: 80px;
      }
    }
  }

  .halftone {
    position: absolute;
    left: 50%;
    height: auto;
    transform: translateX(-50%);

    @include breakpoint(0, xs) {
      width: 800px;
    }

    @include breakpoint(xl, 0) {
      width: 100%;
    }

    &.halftone-top {
      background: linear-gradient(180deg, #eae2f5 0%, #eae2f500 100%);
    }

    &.halftone-top--logged-in {
      top: 131px;

      @include breakpoint(md) {
        top: 0px;
      }
    }

    &.halftone-bottom {
      bottom: 0;
      background: linear-gradient(0deg, rgba(255, 247, 247, 0.6) 50%, rgba(249, 247, 255, 0) 100%);
    }
  }
  .bg-left,
  .bg-right {
    position: absolute;
    top: 0;
    height: 100%;
    img {
      height: 100%;
    }
    @media (min-width: 0) and (max-width: 1242px) {
      display: none;
    }
  }
  .bg-left {
    left: -25vw;
    @media only screen and (min-width: 1200px) {
      left: -12vw;
    }
    @media only screen and (min-width: 1730px) {
      left: 0;
    }
  }
  .bg-right {
    right: -25vw;
    @media only screen and (min-width: 1200px) {
      right: -12vw;
    }
    @media only screen and (min-width: 1730px) {
      right: 0;
    }
  }
}

@media (min-width: $bp-sm-min) and (max-width: $bp-md-max) {
  .container {
    max-width: 90vw;
  }
}

.red-blur {
  box-shadow: 0px 6px 12px 0px rgba(208, 157, 88, 0.5);
  // Default theme
  // box-shadow: 0 9px 17.99999px 0px rgba(242, 100, 140, 0.32);
}

.routes-container {
  position: relative;
  z-index: 1;
}

.text-highlight-primary {
  color: theme('primary');
}

.text-highlight-link {
  color: theme('link');
}
