@import '../../styles/utilities/variables.scss';
@import '../../styles/utilities/mixins.scss';
@import '../../styles/utilities/breakpoints.scss';

.modal-callout {
  &.modal-tune-in-video {
    .modal-tune-in {
      width: 100%;
      background-color: theme('primary');
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include breakpoint(0, md) {
        padding: 8px 12px;
      }

      @include breakpoint(lg, 0) {
        padding: 8px 24px;
      }

      .movie-title {
        display: flex;
        align-items: center;
        color: #fff;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 10px;
        @include breakpoint(sm) {
          font-size: 16px;
        }

        .icon {
          @include breakpoint(0, xs) {
            display: none;
          }

          margin-right: 16px;
        }

        img:not(.icon) {
          @include breakpoint(0, md) {
            width: auto;
            height: auto;
            max-width: 45vw;
            max-height: 32px;
          }
        }
      }

      .tune-in {
        margin-left: auto;
        font-family: $albra;
        font-weight: $font-weight-bold;
        line-height: 100%;
        text-align: right;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: $white;
        white-space: pre;

        @include breakpoint(0, xs) {
          font-size: 10px;
        }

        @include breakpoint(sm, md) {
          font-size: 14px;
        }

        @include breakpoint(lg, 0) {
          font-size: 16px;
        }
      }
    }

    .modal-video {
      margin-bottom: -0.4em;

      iframe {
        @include breakpoint(0, sm) {
          border-radius: 0 0 4px 4px;
        }

        @include breakpoint(md, 0) {
          border-radius: 0 0 6px 6px;
        }
      }

      img {
        width: 100%;
        @include breakpoint(0, sm) {
          border-radius: 0 0 4px 4px;
        }

        @include breakpoint(md, 0) {
          border-radius: 0 0 6px 6px;
        }
      }
    }
  }

  &.modal-tune-in-full {
    img {
      width: 100%;

      @include breakpoint(0, sm) {
        border-radius: 0 0 4px 4px;
      }

      @include breakpoint(md, 0) {
        border-radius: 0 0 6px 6px;
      }
    }
  }
}
