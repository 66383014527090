@import '../../styles/utilities/variables.scss';
@import '../../styles/utilities/mixins.scss';
@import '../../styles/utilities/breakpoints.scss';

.modal {
  z-index: 1051 !important;

  &.intro-modal {
    .modal-dialog {
      @include breakpoint(md, 0) {
        max-width: 580px;
      }
    }

    .modal-footer {
      padding-top: 0;
      padding: 1rem 1rem 1.4rem 1rem;

      @include breakpoint(sm) {
        padding: 1rem 1rem 2rem 1rem;
      }

      @include breakpoint(520, xs) {
        margin-top: 40px;
      }

      .btn {
        @include breakpoint(sm) {
          padding-left: 60px;
          padding-right: 60px;
          width: auto;
          margin: 0;
        }
      }
    }
  }

  &.level-modal,
  &.pause-modal {
    .modal-dialog {
      @include breakpoint(md, 0) {
        max-width: 680px;
      }
    }
  }

  .modal-dialog {
    position: relative;

    .modal-content {
      overflow: hidden;
      @include breakpoint(0, sm) {
        border-radius: 16px;
      }

      @include breakpoint(md, 0) {
        border-radius: 24px;
      }

      .modal-header {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: none;
        padding: 2rem 1rem 1rem 1rem;

        @include breakpoint(sm) {
          padding: 3.2rem 2rem 1rem 2rem;
        }

        h5 {
          text-align: center;
        }
      }

      .modal-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: none;

        p.light.small {
          text-align: center;
        }

        .btn {
          margin: 0;
        }
      }

      .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 2rem;

        .btn {
          @include breakpoint(sm) {
            padding-left: 60px;
            padding-right: 60px;
            width: auto;
            margin: 20px auto 0;
          }
        }

        .completed {
          font-size: 1rem;
          max-width: 450px;
          font-weight: 500;
          line-height: 140%;
          margin-bottom: 1.5rem;

          @include breakpoint(md) {
            font-size: 1.375rem;
          }
        }
      }

      .btn-row {
        display: flex;
        width: 450px;
        margin: 0;
        @include breakpoint(0, xs) {
          flex-direction: column;
          width: 100%;
        }

        @include breakpoint(sm, sm) {
          width: 100%;
        }

        & > .btn,
        a {
          @include breakpoint(0, xs) {
            width: 100%;
          }

          @include breakpoint(sm, sm) {
            width: calc(50% - 8px);
          }

          @include breakpoint(md, 0) {
            width: 310px;
          }
        }

        a > .btn {
          width: 100%;
        }

        .btn {
          margin-left: 0;
          margin-right: 0;
          border-width: 1px;
          padding: 20px 8px;
          border-radius: 2rem;
          @include breakpoint(sm, sm) {
            font-size: 12px;
            padding: 20px 8px;
          }

          &.btn-outline-primary {
            @include breakpoint(0, xs) {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}

.game-tutorial {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;

  @include breakpoint(0, xs) {
    width: calc(100% - 32px);
    margin-bottom: 16px;
    margin-top: 4px;
  }

  @include breakpoint(sm, 0) {
    width: calc((80px * 3) + (40px * 3));
    margin-bottom: 24px;
    margin-top: 12px;
  }

  .game-tutorial-tile {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.05), 2px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    position: relative;
    z-index: 10;
    transform-origin: 50% 50%;
    transition: all 300ms ease-in;
    transform: scale(1);
    opacity: 1;

    @include breakpoint(0, xs) {
      width: calc(((100vw - 72px) / 4) - 8px);
      height: calc(((100vw - 72px) / 4) - 8px);
    }

    @include breakpoint(sm, 0) {
      width: 80px;
      height: 80px;
    }

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      // background: url('../../images/backgrounds/sign.png'), rgba(255, 255, 255, 0.2);
      background: rgba(255, 255, 255, 0.2);
      background-size: cover;
      opacity: 0;
      pointer-events: none;
      transition: all 200ms ease-in;
    }

    &.game-tutorial-tile-clickable {
      cursor: pointer;

      &:hover {
        &::after {
          opacity: 1;
        }
      }
    }

    &:not(.game-tutorial-tile-clickable) {
      pointer-events: none;
    }

    &:nth-of-type(n + 5) {
      @include breakpoint(0, xs) {
        margin-top: 10px;
      }

      @include breakpoint(sm, 0) {
        margin-top: 16px;
      }
    }

    &.game-tutorial-tile-hallmark-blue {
      background: url('../../images/backgrounds/tile-outline.svg'),
        url('../../images/backgrounds/game-tutorial-tile-hallmark.png'),
        linear-gradient(108.99deg, #409fe4 0%, #49c6e8 49.48%, #5bdced 100%);
      background-size: cover, cover, cover;
      background-position: 50% 50%, 50% 50%, 50% 50%;
    }

    &.game-tutorial-tile-hallmark-gold {
      background: url('../../images/backgrounds/game-tutorial-tile-hallmark-gold.png'), #f08e1d;
      background-size: cover, cover, cover;
      background-position: 50% 50%, 50% 50%, 50% 50%;
    }

    &.game-tutorial-tile-hallmark-red {
      background: url('../../images/backgrounds/tile-outline.svg'),
        url('../../images/backgrounds/game-tutorial-tile-hallmark.png'),
        linear-gradient(108.99deg, #ba0000 0%, #e21219 50.52%, #ff3a41 100%);
      background-size: cover, cover, cover;
      background-position: 50% 50%, 50% 50%, 50% 50%;
    }

    &.game-tutorial-tile-hallmark-purple {
      background: url('../../images/backgrounds/game-tutorial-tile-hallmark-channel.png'),
        linear-gradient(134.79deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 100%), #a91e82;

      background-size: cover, cover, cover;
      background-position: 50% 50%, 50% 50%, 50% 50%;
    }

    &.game-tutorial-tile-hallmark-logotype {
      background: url('../../images/backgrounds//game-tutorial-tile-m.png');
      background-size: cover, cover, cover;
      background-position: 50% 50%, 50% 50%, 50% 50%;
    }

    &.game-tutorial-tile-matching-moments-purple {
      background: url('../../images/backgrounds/game-tutorial-tile-matching-moments.png'),
        linear-gradient(108.99deg, #51176e 0%, #802595 49.48%, #802595 100%);
      background-size: cover, cover, cover;
      background-position: 50% 50%, 50% 50%, 50% 50%;
    }

    &.game-tutorial-tile-matching-moments-green {
      background: url('../../images/backgrounds/game-tutorial-tile-matching-moments.png'),
        linear-gradient(135.35deg, #5ca524 0%, #67b327 52.5%, #95d763 100%);
      background-size: cover, cover, cover;
      background-position: 50% 50%, 50% 50%, 50% 50%;
    }

    &.game-tutorial-tile-matching-moments-orange {
      background: url('../../images/backgrounds/game-tutorial-tile-matching-moments.png'),
        url('../../images/backgrounds/gold-texture.jpg');
      // background: url('../../images/backgrounds/cruise-tile.png');
      background-size: cover, cover, cover;
      background-position: 50% 50%, 50% 50%, 50% 50%;
    }

    &.game-tutorial-tile-flowers {
      background: url('../../images/backgrounds/tile-outline.svg'),
        url('../../images/backgrounds/game-tutorial-tile-hallmark-flowers.png');
      background-size: cover, cover;
      background-position: 50% 50%, 50% 50%;
    }

    &.game-tutorial-tile-wcth {
      background: url('../../images/backgrounds/tile-outline.svg'),
        url('../../images/backgrounds/game-tutorial-tile-wcth.png'), #eee;
      background-size: cover, cover, cover;
      background-position: 50% 50%, 50% 50%, 50% 50%;
    }

    &.game-tutorial-tile-empty {
      opacity: 0;
      visibility: hidden;
    }
  }

  .game-tutorial-lines {
    position: absolute;
    pointer-events: none;
    z-index: 5;
    bottom: 0;
    left: 50%;
    height: calc((1094 / 872) * 100%);
    width: calc((545 / 424) * 100%);
    transform: translateX(-50%);
  }

  .game-tutorial-line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    transform-origin: 50% 50%;
    transition: all 300ms ease-in;
    opacity: 0;
  }
}

.modal-body {
  p {
    @include breakpoint(sm) {
      margin-bottom: 0 !important;
    }
  }
}

.level-modal {
  .modal-body {
    padding-top: 0;
    text-align: center;
  }
}

.game-over-modal {
  h5 {
    font-size: 1.35rem;

    @include breakpoint(xs) {
      font-size: 1.5rem;
    }
  }
}

.intro-modal {
  .modal-body {
    max-height: 400px;
    transition: all 300ms ease-in;
  }
  .modal-footer {
    padding-top: 0 !important;
  }

  .modal-newbadge {
    margin-top: -0.5rem;
    max-height: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .modal-newbadge-title {
      color: theme('link');
      font-weight: $font-weight-bold;
      font-size: 2rem;
      margin-bottom: 0.3rem;
    }

    .modal-newbadge-image {
      max-height: 180px;
    }

    .modal-newbadge-description {
      max-width: 360px;
      padding: 1rem 0;
    }
  }

  .modal-header {
    .horizontal-logo {
      @include breakpoint(0, lg) {
        height: 29.22px;
        width: 310px;
        margin-bottom: 8px;
      }

      @include breakpoint(lg, 0) {
        height: 45px;
        width: 472.25px;
        margin-bottom: 16px;
      }
    }

    h5 {
      @include breakpoint(0, xs) {
        max-width: 300px;
        font-size: 1rem;
      }

      @include breakpoint(sm, sm) {
        max-width: 440px;
        font-size: 1.1rem;
      }

      @include breakpoint(md, 0) {
        max-width: 520px;
      }
    }
  }

  p {
    @include breakpoint(0, xs) {
      max-width: 100%;
    }
  }

  &.intro-modal-step-1 {
    .game-tutorial-line {
      opacity: 0;
    }
  }
  &.intro-modal-step-2 {
    .game-tutorial-line {
      &:nth-of-type(n + 2) {
        opacity: 0;
      }

      &:nth-of-type(1) {
        opacity: 1;
      }
    }

    .game-tutorial-tile {
      &:not(.game-tutorial-tile-hallmark-logotype):not(.game-tutorial-tile-hallmark-red):not(
          .game-tutorial-tile-hallmark-purple
        ) {
        opacity: 0.3;
      }
      &.game-tutorial-tile-hallmark-logotype,
      &.game-tutorial-tile-hallmark-red,
      &.game-tutorial-tile-hallmark-purple {
        transform: scale(1.1);
      }
    }
  }

  &.intro-modal-step-3,
  &.intro-modal-step-5 {
    .game-tutorial-line {
      opacity: 0;
    }

    .game-tutorial-tile {
      &.game-tutorial-tile-hallmark-logotype,
      &.game-tutorial-tile-hallmark-red,
      &.game-tutorial-tile-hallmark-purple {
        opacity: 0;
        transform: scale(0);
      }
    }
  }

  &.intro-modal-step-4 {
    .game-tutorial-line {
      &:nth-of-type(1),
      &:nth-of-type(n + 3) {
        opacity: 0;
      }

      &:nth-of-type(2) {
        opacity: 1;
      }
    }

    .game-tutorial-tile {
      &.game-tutorial-tile-hallmark-logotype,
      &.game-tutorial-tile-hallmark-purple,
      &.game-tutorial-tile-hallmark-red {
        opacity: 0;
      }

      &.game-tutorial-tile-hallmark-blue,
      &.game-tutorial-tile-hallmark-gold,
      &.game-tutorial-tile-matching-moments-purple {
        opacity: 0.3;
      }

      &.game-tutorial-tile-flowers,
      &.game-tutorial-tile-matching-moments-green,
      &.game-tutorial-tile-matching-moments-orange {
        transform: scale(1.1);
      }
    }
  }

  &.intro-modal-step-5 {
    .game-tutorial-tile {
      &.game-tutorial-tile-flowers,
      &.game-tutorial-tile-matching-moments-green,
      &.game-tutorial-tile-matching-moments-orange {
        -webkit-animation: shakeTile 200ms 1 cubic-bezier(0.36, 0.07, 0.19, 0.97);
        animation: shakeTile 200ms 1 cubic-bezier(0.36, 0.07, 0.19, 0.97);
        -webkit-transform: translateX(0);
        transform: translateX(0);

        @keyframes shakeTile {
          0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
          }

          10% {
            -webkit-transform: translateX(-1px);
            transform: translateX(-1px);
          }

          50% {
            -webkit-transform: translateX(1px);
            transform: translateX(1px);
          }

          90% {
            -webkit-transform: translateX(-1px);
            transform: translateX(-1px);
          }

          100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
          }
        }

        @-webkit-keyframes shakeTile {
          0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
          }

          10% {
            -webkit-transform: translateX(-1px);
            transform: translateX(-1px);
          }

          50% {
            -webkit-transform: translateX(1px);
            transform: translateX(1px);
          }

          90% {
            -webkit-transform: translateX(-1px);
            transform: translateX(-1px);
          }

          100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
          }
        }
      }
    }
  }

  &.intro-modal-step-6 {
    .game-tutorial-line {
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(n + 4) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        opacity: 1;
      }
    }

    .game-tutorial-tile {
      &.game-tutorial-tile-hallmark-logotype,
      &.game-tutorial-tile-hallmark-red,
      &.game-tutorial-tile-hallmark-purple {
        opacity: 0;
      }

      &.game-tutorial-tile-hallmark-blue,
      &.game-tutorial-tile-flowers,
      &.game-tutorial-tile-hallmark-gold,
      &.game-tutorial-tile-matching-moments-green,
      &.game-tutorial-tile-matching-moments-orange {
        opacity: 0.3;
      }

      &.game-tutorial-tile-matching-moments-purple {
        transform: scale(1.1);
      }
    }
  }

  &.intro-modal-step-7 {
    .game-tutorial-line {
      opacity: 0;
    }

    .game-tutorial-tile {
      &.game-tutorial-tile-hallmark-logotype,
      &.game-tutorial-tile-hallmark-red,
      &.game-tutorial-tile-hallmark-purple,
      &.game-tutorial-tile-matching-moments-purple {
        opacity: 0;
      }

      &.game-tutorial-tile-matching-moments-purple {
        transform: scale(0);
      }
    }
  }

  &.intro-modal-step-8 {
    .game-tutorial-line {
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(n + 5) {
        opacity: 0;
      }

      &:nth-of-type(4) {
        opacity: 1;
      }
    }

    .game-tutorial-tile {
      &.game-tutorial-tile-hallmark-logotype,
      &.game-tutorial-tile-hallmark-red,
      &.game-tutorial-tile-hallmark-purple,
      &.game-tutorial-tile-matching-moments-purple {
        opacity: 0;
      }

      &.game-tutorial-tile-hallmark-blue,
      &.game-tutorial-tile-hallmark-gold {
        opacity: 0.3;
      }

      &.game-tutorial-tile-flowers,
      &.game-tutorial-tile-matching-moments-green,
      &.game-tutorial-tile-matching-moments-orange {
        transform: scale(1.1);
      }
    }
  }

  &.intro-modal-step-9 {
    .game-tutorial-line {
      opacity: 0;
    }

    .game-tutorial-tile {
      &.game-tutorial-tile-hallmark-logotype,
      &.game-tutorial-tile-hallmark-red,
      &.game-tutorial-tile-hallmark-purple,
      &.game-tutorial-tile-matching-moments-purple,
      &.game-tutorial-tile-flowers,
      &.game-tutorial-tile-matching-moments-green,
      &.game-tutorial-tile-matching-moments-orange {
        opacity: 0;
      }

      &.game-tutorial-tile-flowers,
      &.game-tutorial-tile-matching-moments-orange {
        transform: scale(0);
      }
    }
  }

  &.intro-modal-step-10 {
    .game-tutorial-line {
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(n + 6) {
        opacity: 0;
      }

      &:nth-of-type(5) {
        opacity: 1;
      }
    }

    .game-tutorial-tile {
      &.game-tutorial-tile-hallmark-logotype,
      &.game-tutorial-tile-hallmark-red,
      &.game-tutorial-tile-hallmark-purple,
      &.game-tutorial-tile-matching-moments-purple,
      &.game-tutorial-tile-flowers,
      &.game-tutorial-tile-matching-moments-green,
      &.game-tutorial-tile-matching-moments-orange {
        opacity: 0;
      }

      &.game-tutorial-tile-hallmark-blue,
      &.game-tutorial-tile-hallmark-gold {
        transform: scale(1.1);
      }
    }
  }

  &.intro-modal-step-11 {
    .game-tutorial-line {
      opacity: 0;
    }

    .game-tutorial-tile {
      opacity: 0;
      transform: scale(0);
    }
  }
}

.intro-text-anim-container {
  position: relative;

  @include breakpoint(0, xs) {
    width: 270px;
  }

  @include breakpoint(sm, sm) {
    width: 400px;
  }
  @include breakpoint(xl, 0) {
  }
}

.intro-text-anim {
  font-size: 1.1rem;
  text-align: center;
  margin-bottom: 0;
  transition: all 200ms ease-in;
  padding-left: 1rem;
  padding-right: 1rem;

  &:not(.intro-text-anim-step1) {
    opacity: 0;
  }

  &:not(.intro-text-anim-step1) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    @include breakpoint(0, xs) {
      transform: translate(-50%, 30%);
    }
  }
}

@for $i from 1 through 11 {
  .intro-modal-step-#{$i} {
    .intro-text-anim {
      &:not(.intro-text-anim-step#{$i}) {
        opacity: 0;
      }

      &.intro-text-anim-step#{$i} {
        opacity: 1;
      }
    }
  }
}

.score-table {
  border-collapse: initial;
  table-layout: fixed;

  tbody {
    display: flex;
    align-items: flex-start;
  }

  .score-table-value {
    display: flex;
    align-items: center;
  }

  td {
    h3 {
      text-align: center;
      font-size: 0.625rem;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: 0.03125rem;
      text-transform: uppercase;

      @include breakpoint(md) {
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: 0.05rem;
      }
    }

    h4 {
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 0;

      @include breakpoint(0, 340) {
        font-size: 0.9rem;
      }

      &.link {
        color: theme('modalIcons');
      }
    }

    svg {
      @include breakpoint(0, xs) {
        width: 32px;
        height: 32px;
      }

      @include breakpoint(sm, 0) {
        width: 40px;
        height: 40px;
      }

      .icon-fill-path {
        fill: theme('modalIcons');
      }
    }
  }
}

.pause-modal-backdrop {
  background-color: rgba(0, 0, 0, 0.96) !important;

  &.show {
    opacity: 1 !important;
  }

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    -webkit-backdrop-filter: blur(54px);
    backdrop-filter: blur(54px);
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
}

.sns-modal {
  $logo-height-xs: 100px;
  $logo-height-xl: 140px;

  .modal-dialog {
    @include breakpoint(0, sm) {
      padding-top: calc(#{$logo-height-xs} / 2);
    }

    @include breakpoint(md) {
      padding-top: calc(#{$logo-height-xl} / 2);
    }
  }

  .modal-content {
    background: url('../../images/backgrounds/background-clouds.svg'),
      linear-gradient(180deg, rgba(255, 255, 255, 0) 19.79%, #ffffff 70.83%), #d9eafe;
    background-size: 100% auto, cover, cover;
    background-position: center top, center center, center center;
    background-repeat: no-repeat, no-repeat, no-repeat;

    .modal-header {
      z-index: 20;
      padding: 0 !important;

      @include breakpoint(0, sm) {
        margin-bottom: calc(#{$logo-height-xs} / -2);
      }

      @include breakpoint(md) {
        margin-bottom: calc(#{$logo-height-xl} / -2);
      }

      .sns-logo {
        width: auto;
        transform: translate(0, -50%);

        @include breakpoint(0, sm) {
          height: $logo-height-xs;
        }

        @include breakpoint(md) {
          height: $logo-height-xl;
        }
      }
    }

    .modal-body {
      padding-top: 1em !important;

      @include breakpoint(0, sm) {
        padding-left: 8px;
        padding-right: 8px;
      }

      h5 {
        text-align: center;
      }

      .btn-primary {
        margin: 1em auto;
        width: 100%;

        @include breakpoint(0, 409.98) {
          font-size: 13px;
          padding: 16px 2px;

          svg {
            display: none;
          }
        }

        @include breakpoint(410, sm) {
          font-size: 13px;
          padding: 16px 12px;
          max-width: 410px;
        }

        @include breakpoint(md) {
          font-size: 16px;
          padding: 16px 24px;
        }
      }

      .btn {
        position: relative;
        z-index: 20;
      }
    }
  }
}

.sns-promo {
  background: linear-gradient(180deg, #ffffff 19.79%, rgba(255, 255, 255, 0) 70.83%), #d9eafe;
  border-radius: 0 0 0.3rem 0.3rem;
  position: relative;

  a {
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
      max-width: 220px;
      max-height: 80%;
      width: auto;
      height: auto;
      z-index: 10;
      transition: all 250ms ease-in;
    }

    @include onHover() {
      img {
        transform: translate(-50%, -50%) scale(1.05);
      }
    }
  }

  .sns-background {
    @include breakpoint(0, sm) {
      margin-top: 0.5rem;
    }
  }
}

.sns-background {
  position: relative;
  overflow: hidden;
  border-radius: 0 0 0.3rem 0.3rem;
  width: 100%;

  @include breakpoint(0, sm) {
    margin-top: -0.5rem;
  }

  @include breakpoint(md) {
    margin-top: -1rem;
  }

  img {
    width: 100%;
    height: auto;

    &:nth-of-type(1) {
      position: relative;
      z-index: 1;
    }

    &:nth-of-type(2) {
      z-index: 2;
    }

    &:nth-of-type(3) {
      z-index: 3;
    }

    &:nth-of-type(n + 2) {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
.modal-profile-pic {
  .modal-dialog {
    max-width: 580px;
    .modal-content {
      &:after {
        border: none;
      }
      .modal-header {
        padding: 2rem 1rem 0;

        .modal-heading {
          font-size: 1.6rem;
          color: theme('primary');
          font-weight: $font-weight-bold;
          letter-spacing: 0.025rem;

          @include breakpoint(md) {
            font-size: 2.3rem;
          }
        }
      }
    }
    .modal-confirm {
      margin: 1rem auto 1.5rem;
      width: 100%;
      @include breakpoint(md) {
        margin: 2rem auto 2.5rem;
      }
    }
  }
}
.modal-close {
  color: #802595;
  font-weight: 900;
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background-color: transparent;
  &:after {
    content: url(../../images/icons/iconClose.svg);
  }
}

.modal-time-score-container {
  display: flex;
  align-items: center;
  gap: 1px;
  background: rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0.75rem;
  height: 85px;
  width: 100%;
  overflow: hidden;

  @include breakpoint(md) {
    height: 119px;
    border-radius: 1.5rem;
  }

  .modal-time-score {
    overflow: visible;
    background: $white;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    align-items: center;
    justify-content: center;

    img {
      width: 32px;
      height: 32px;
      border-radius: 100%;

      @include breakpoint(md) {
        width: 48px;
        height: 48px;
      }
    }
  }

  .modal-time-score__title {
    color: theme('primary');
    font-size: 0.625rem;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.03125rem;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0.25rem;

    @include breakpoint(md) {
      font-size: 1rem;
      letter-spacing: 0.05rem;
      margin-bottom: 0.5rem;
    }
  }

  .modal-time-score__content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;

    @include breakpoint(md) {
      gap: 1rem;
    }
  }

  .modal-time-score__value {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 120%;
    position: relative;

    @include breakpoint(md) {
      font-size: 2.25rem;
      line-height: 110%;
    }
  }

  .score-table_total-score {
    position: relative;

    h4 {
      position: relative;

      span:not(.hidden) {
        position: absolute;
        left: 0;
      }

      span.hidden {
        pointer-events: none;
        opacity: 0;
      }
    }
  }

  .time-bonus {
    display: flex;
    align-items: center;
    position: absolute;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    opacity: 0;
    background: $white;
    z-index: 5;
    min-width: 100%;
    background: transparent;

    @include breakpoint(0, sm) {
      top: 36px;
      justify-content: center;
      -webkit-animation: showBonusMobile 1800ms 1 400ms cubic-bezier(0.36, 0.07, 0.19, 0.97);
      animation: showBonusMobile 1800ms 1 400ms cubic-bezier(0.36, 0.07, 0.19, 0.97);
    }

    @include breakpoint(md, 0) {
      top: -4px;
      left: calc(100% + 2px);
      -webkit-animation: showBonusDesktop 1000ms 1 800ms cubic-bezier(0.36, 0.07, 0.19, 0.97);
      animation: showBonusDesktop 1000ms 1 800ms cubic-bezier(0.36, 0.07, 0.19, 0.97);
    }

    @-webkit-keyframes showBonusMobile {
      from {
        opacity: 0;
        transform: translateY(0);
      }
      40% {
        opacity: 1;
        transform: translateY(-50%);
      }

      60% {
        opacity: 1;
        transform: translateY(-50%);
      }
      to {
        opacity: 0;
        transform: translateY(-100%);
      }
    }

    @keyframes showBonusMobile {
      from {
        opacity: 0;
        transform: translateY(0);
      }
      40% {
        opacity: 1;
        transform: translateY(-50%);
      }

      60% {
        opacity: 1;
        transform: translateY(-50%);
      }
      to {
        opacity: 0;
        transform: translateY(-100%);
      }
    }

    @-webkit-keyframes showBonusDesktop {
      from {
        opacity: 0;
        transform: translateY(100%);
      }
      40% {
        opacity: 1;
        transform: translateY(50%);
      }

      60% {
        opacity: 1;
        transform: translateY(50%);
      }
      to {
        opacity: 0;
        transform: translateY(0);
      }
    }

    @keyframes showBonusDesktop {
      from {
        opacity: 0;
        transform: translateY(100%);
      }
      40% {
        opacity: 1;
        transform: translateY(50%);
      }

      60% {
        opacity: 1;
        transform: translateY(50%);
      }
      to {
        opacity: 0;
        transform: translateY(0);
      }
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;

      @include breakpoint(0, sm) {
        display: none;
      }

      .icon-fill-path {
        fill: theme('modalIcons');
      }
    }

    p {
      margin-bottom: 0;
      font-weight: $font-weight-bold;
      color: theme('modalIcons');

      @include breakpoint(0, sm) {
        font-size: 0.7rem;
      }

      @include breakpoint(sm) {
        font-size: 0.9rem;
      }

      @include breakpoint(md, 0) {
        font-size: 14px;
      }
    }
  }
}

.pause-modal {
  h4 {
    color: theme('primary');
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 110%;
    margin-bottom: 0.5rem;

    @include breakpoint(md) {
      font-size: 2.25rem;
      margin-bottom: 1.5rem;
    }
  }

  .pause-modal__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 120%;

    @include breakpoint(md) {
      font-size: 2.25rem;
      font-weight: 700;
      line-height: 110%;
    }

    img {
      width: 32px;
      height: 32px;
      border-radius: 100%;

      @include breakpoint(md) {
        width: 48px;
        height: 48px;
      }
    }
  }
}

.modal-disclaimer {
  line-height: 1.2;
}

.modal-content {
  h3 {
    color: theme('primary');
    font-size: 1.75rem;
    line-height: 110%;
    text-align: center;
    margin-bottom: 1.75rem;

    @include breakpoint(md) {
      font-size: 2.25rem;
      margin-bottom: 2rem;
    }
  }
}
