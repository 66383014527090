.page-leaderboard {
  @include breakpoint(1280, 0) {
    min-height: 600px;
  }
}

.leaderboard-container {
  @include breakpoint(1280, 0) {
    width: calc(100vw - 586px);
  }

  @include breakpoint(1700) {
    width: calc(100vw - 648px);
  }

  h1 {
    font-size: 1.7rem;
    margin-bottom: 1rem;
    text-align: center;

    @include breakpoint(sm) {
      font-size: 2rem;
    }

    @include breakpoint(md) {
      font-size: 2.3rem;
      text-align: left;
    }
  }

  h3 {
    color: theme('primary');
  }
}

.leaderboard-header {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;

  @include breakpoint(0, md) {
    margin: 16px 0;
  }

  @include breakpoint(lg, 0) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 16px;
    grid-template-areas: 'button header space';
    margin: 40px 0;

    .leaderboard-header_button {
      grid-area: button;
    }

    .leaderboard-header_header {
      grid-area: header;
    }

    .leaderboard-header_space {
      grid-area: space;
    }

    .leaderboard-header_toggle {
      .button-group {
        display: flex;
        justify-content: flex-end;

        .weekly {
          margin-right: 1rem;
        }
      }
    }
  }

  .btn-leaderboard-right {
    background-color: transparent;
    border-color: theme('default');

    span {
      color: theme('default');

      &.btn-content {
        svg {
          path {
            fill: theme('default');
          }
        }
      }
    }
  }

  .button-group {
    .active {
      background-color: theme('modalIcons') !important;
      border-color: theme('modalIcons') !important;
      box-shadow: 0px 4px 12px 0px rgba(240, 142, 29, 0.64) !important;

      &:hover,
      &:focus,
      &:active {
        background-color: theme('modalIcons');
        border-color: theme('modalIcons');

        span {
          color: $white;
        }
      }
    }

    :not(.active) {
      border-color: theme('default');
      background-color: theme('default');
      span {
        color: theme('default');
      }
    }
  }

  .leaderboard-header_button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    order: 2;

    @include breakpoint(sm) {
      align-items: center;
      flex-direction: row;
    }

    .group-buttons {
      gap: 10px;

      @include breakpoint(0, sm) {
        width: 100%;
      }

      @include breakpoint(md) {
        gap: 15px;
      }

      .btn {
        font-size: 1rem;

        @include breakpoint(sm) {
          padding: 16px 24px;
        }

        @include breakpoint(0, sm) {
          width: 48%;
        }

        &.active {
          background-color: theme('modalIcons');
          border-color: theme('modalIcons');
          box-shadow: 0px 4px 12px 0px rgba(240, 142, 29, 0.64) !important;

          &:hover,
          &:focus,
          &:active {
            background-color: theme('modalIcons');
          }
        }
      }
    }

    @include breakpoint(0, md) {
      span {
        color: theme('default');
      }

      .back-to-game {
        display: none;
      }
    }

    .btn-icon-before {
      max-width: 100%;
      font-weight: 600;

      @include breakpoint(0, md) {
        letter-spacing: 0.03em;

        svg {
          margin-right: 8px;

          path {
            fill: theme('link');
          }
        }
      }

      @include breakpoint(1280, 1500) {
        svg {
          margin-right: 4px;
        }
      }
    }
  }

  .leaderboard-header_header {
    margin-top: 20px;
    order: 1;

    @include breakpoint(lg) {
      margin-top: 6px;
    }

    h1 {
      text-align: center;

      @include breakpoint(0, xs) {
        font-size: 1.4rem;
      }

      @include breakpoint(sm, md) {
        font-size: 1.8rem;
      }

      @include breakpoint(1280, 1500) {
        font-size: 26px;
      }
    }
  }

  .btn-leaderboard:hover,
  .btn-leaderboard:focus {
    span {
      color: $white;
      border-color: theme('primary');
    }
  }
}

.leaderboard-table {
  table-layout: fixed;
  width: 100%;
  margin-bottom: 40px;
  overflow: hidden;
  border-radius: 8px;

  @include breakpoint(sm) {
    border-radius: 16px;
  }

  .leaderboard-table_user-rank {
    text-align: center;
    width: 15%;

    @include breakpoint(sm) {
      width: 5%;
    }
  }

  tbody {
    position: relative;
    background: $white;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 8px;

      @include breakpoint(sm) {
        border-radius: 16px;
      }
    }
  }

  th p {
    text-transform: uppercase;
    color: #333;
    font-size: 12px;
  }

  td,
  th {
    padding: 12px;

    p {
      margin-bottom: 0;
    }
  }

  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    &:last-child {
      border-bottom: none;
    }
  }

  .profile-info {
    display: flex;
    align-items: center;

    .leaderboard-image {
      max-width: 29px;
      margin-right: 0.2rem;
    }

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .points-total {
    width: 31%;
  }
}

.leaderboard-header_button {
  grid-area: button;

  .button-group {
    display: flex;
    gap: 10px;

    .btn-sm {
      font-weight: 600;
      letter-spacing: 0.03em;
    }
  }
}

.leaderboard-table_name {
  text-align: left;
}

.button-group {
  .btn {
    font-weight: 600;
    font-size: 1rem;

    @include breakpoint(sm) {
      padding: 16px 24px;
    }
  }

  @include breakpoint(0, sm) {
    width: 100%;

    .btn {
      width: 48%;
    }
  }
}

.header-group-member {
  @include breakpoint(sm) {
    width: 160px;
  }
}

.header-points {
  @include breakpoint(sm) {
    width: 200px;
  }
}
