$white: #ffffff;
$text: #363636;
$hallmark-red: #e21219;

$gotham: 'Gotham SSm A', 'Gotham SSm B', Tahoma, Helvetica, sans-serif;
$albra: 'AlbraGrotesk', Tahoma, Helvetica, sans-serif;
$font-weight-light: 300;
$font-weight-book: 400;
$font-weight-medium: 500;
$font-weight-semi: 600;
$font-weight-bold: 700;
$font-weight-black: 800;

$text-light: #868686;
$input-outline: #0000001f;

$theme-colors: (
  'default': (
    'gradient': linear-gradient(108.99deg, #51176e 0%, #652b82 49.48%, #793f96 100%),
    'headerBg': #f9f7ff,
    'primary': #652b82,
    'secondary': #cc667a,
    'primaryHover': darken(#652b82, 10%),
    'primaryFocus': rgba(#652b82, 0.3),
    'ribbonBg': #652b82,
    'link': #652b82,
    'linkFocus': '#cc667a',
    'linkHover': '#cc667a',
    'modalOutline': #e6d6f5,
    'tileGradient': linear-gradient(108.99deg, #409fe4 0%, #49c6e8 49.48%, #5bdced 100%),
    'modalIcons': #f08e1d,
  ),
  'loveuary2023': (
    'gradient': linear-gradient(108.99deg, #51176e 0%, #652b82 49.48%, #793f96 100%),
    'headerBg': #f9f7ff,
    'primary': #1f4047,
    'secondary': #cc667a,
    'primaryHover': darken(#1f4047, 10%),
    'primaryFocus': rgba(#1f4047, 0.3),
    'ribbonBg': #1f4047,
    'link': #be5427,
    'linkFocus': darken(#be5427, 10%),
    'linkHover': darken(#be5427, 20%),
    'modalOutline': #e6d6f5,
    'tileGradient': linear-gradient(108.99deg, #409fe4 0%, #49c6e8 49.48%, #5bdced 100%),
    'modalIcons': #be5427,
  ),
  'chex2023': (
    'gradient': linear-gradient(108.99deg, #51176e 0%, #652b82 49.48%, #793f96 100%),
    'headerBg': #f9f7ff,
    'primary': #0e5432,
    'secondary': #cc667a,
    'primaryHover': darken(#0e5432, 10%),
    'primaryFocus': rgba(#0e5432, 0.3),
    'ribbonBg': #0e5432,
    'link': #0e5432,
    'linkFocus': rgba(#a91e82, 0.3),
    'linkHover': darken(#a91e82, 5%),
    'modalOutline': #e6d6f5,
    'tileGradient': linear-gradient(108.99deg, #409fe4 0%, #49c6e8 49.48%, #5bdced 100%),
    'modalIcons': #ee2d24,
  ),
  'spring-fling': (
    'gradient': linear-gradient(108.99deg, #6ea800 0%, #82bc00 49.48%, #96d014 100%),
    'headerBg': #f7f9ff,
    'primary': #82bc00,
    'primaryHover': darken(#82bc00, 5%),
    'primaryFocus': rgba(#82bc00, 0.3),
    'ribbonBg': linear-gradient(90.87deg, #ff93c9 -10.44%, #e76ba1 104.55%),
    'link': #1d7b8c,
    'linkFocus': rgba(#1d7b8c, 0.3),
    'linkHover': darken(#1d7b8c, 5%),
    'modalOutline': #e6d6f5,
    'tileGradient': linear-gradient(108.99deg, #409fe4 0%, #49c6e8 49.48%, #5bdced 100%),
    'modalIcons': #fb7fb5,
  ),
  'fall-harvest': (
    'gradient': linear-gradient(108.99deg, #de6126 0%, #d45c23 100%),
    'headerBg': #f9f7ff,
    'primary': #de6126,
    'primaryHover': darken(#d45c23, 10%),
    'primaryFocus': rgba(#d45c23, 0.3),
    'ribbonBg': linear-gradient(135.35deg, #dc7a09 0%, #f08e1d 52.5%, #ffa231 100%),
    'link': #de6126,
    'linkFocus': rgba(#de6126, 0.3),
    'linkHover': darken(#de6126, 5%),
    'modalOutline': #e6d6f5,
    'tileGradient': linear-gradient(108.99deg, #de6126 0%, #d45c23 100%),
    'modalIcons': #d45c23,
  ),
  'christmas': (
    'gradient': linear-gradient(108.99deg, #ba0000 0%, #ff3a41 100%),
    'headerBg': #f9f7ff,
    'primary': #347aa7,
    'primaryHover': darken(#347aa7, 10%),
    'primaryFocus': rgba(#347aa7, 0.3),
    'ribbonBg': linear-gradient(135.35deg, #347aa7 0%, #215d83 52.5%, #347aa7 100%),
    'link': #db0032,
    'linkFocus': rgba(#347aa7, 0.3),
    'linkHover': darken(#347aa7, 5%),
    'modalOutline': #e6d6f5,
    'tileGradient': linear-gradient(108.99deg, #347aa7 0%, #215d83 100%),
    'modalIcons': #347aa7,
  ),
  'new-year-new-movies': (
    'gradient': linear-gradient(108.99deg, #511759 0%, #511759 100%),
    'headerBg': #f9f7ff,
    'primary': #511759,
    'primaryHover': darken(#511759, 10%),
    'primaryFocus': rgba(#511759, 0.3),
    'ribbonBg': linear-gradient(135.35deg, #347aa7 0%, #215d83 52.5%, #347aa7 100%),
    'link': #802595,
    'linkFocus': rgba(#802595, 0.3),
    'linkHover': darken(#802595, 5%),
    'modalOutline': #e6d6f5,
    'tileGradient': linear-gradient(108.99deg, #347aa7 0%, #215d83 100%),
    'modalIcons': #511759,
  ),
  'loveuary': (
    'gradient': linear-gradient(108.99deg, #de204e 0%, #f4849f 100%),
    'headerBg': #f9f7ff,
    'primary': #de204e,
    'primaryHover': darken(#d61d4a, 10%),
    'primaryFocus': rgba(#d61d4a, 0.3),
    'ribbonBg': linear-gradient(135.35deg, #347aa7 0%, #215d83 52.5%, #347aa7 100%),
    'link': #de204e,
    'linkFocus': rgba(#de204e, 0.3),
    'linkHover': darken(#de204e, 5%),
    'modalOutline': #e6d6f5,
    'tileGradient': linear-gradient(108.99deg, #347aa7 0%, #215d83 100%),
    'modalIcons': #de204e,
  ),
  'spring-into-love': (
    'gradient': linear-gradient(108.99deg, #13874d 0%, #13874d 100%),
    'headerBg': #f9f7ff,
    'primary': #802595,
    'secondary': #13874d,
    'primaryHover': darken(#802595, 10%),
    'primaryFocus': rgba(#802595, 0.3),
    'ribbonBg': linear-gradient(135.35deg, #13874d 0%, #13874d 52.5%, #13874d 100%),
    'link': #13874d,
    'linkFocus': rgba(#802595, 0.3),
    'linkHover': darken(#802595, 5%),
    'modalOutline': #e6d6f5,
    'tileGradient': linear-gradient(108.99deg, #347aa7 0%, #215d83 100%),
    'modalIcons': #802595,
  ),
  'christmas-in-july': (
    'gradient': linear-gradient(108.99deg, #51176e 0%, #652b82 49.48%, #793f96 100%),
    'headerBg': #f9f7ff,
    'primary': #652b82,
    'secondary': #652b82,
    'primaryHover': darken(#652b82, 10%),
    'primaryFocus': rgba(#652b82, 0.3),
    'ribbonBg': linear-gradient(135.35deg, #dc7a09 0%, #f08e1d 52.5%, #ffa231 100%),
    'link': #df3237,
    'linkFocus': rgba(#df3237, 0.3),
    'linkHover': darken(#df3237, 5%),
    'modalOutline': #e6d6f5,
    'tileGradient': linear-gradient(108.99deg, #409fe4 0%, #49c6e8 49.48%, #5bdced 100%),
    'modalIcons': #df3237,
  ),
  'fall-into-love': (
    'gradient': linear-gradient(108.99deg, #51176e 0%, #652b82 49.48%, #793f96 100%),
    'headerBg': #f9f7ff,
    'primary': #652b82,
    'secondary': #652b82,
    'primaryHover': darken(#652b82, 10%),
    'primaryFocus': rgba(#652b82, 0.3),
    'ribbonBg': linear-gradient(135.35deg, #dc7a09 0%, #f08e1d 52.5%, #ffa231 100%),
    'link': #c25606,
    'linkFocus': rgba(#c25606, 0.3),
    'linkHover': darken(#c25606, 5%),
    'modalOutline': #e6d6f5,
    'tileGradient': linear-gradient(108.99deg, #409fe4 0%, #49c6e8 49.48%, #5bdced 100%),
    'modalIcons': #c25606,
  ),
  'chex': (
    'gradient': linear-gradient(108.99deg, #a80808 0%, #a80808 49.48%, #a80808 100%),
    'headerBg': #f9f7ff,
    'primary': #a80808,
    'primaryHover': darken(#a80808, 10%),
    'primaryFocus': rgba(#a80808, 0.3),
    'secondary': #205b42,
    'secondaryHover': darken(#205b42, 10%),
    'secondaryFocus': rgba(#205b42, 0.3),
    'ribbonBg': linear-gradient(135.35deg, #a80808 0%, #a80808 52.5%, #a80808 100%),
    'link': #a80808,
    'linkFocus': rgba(#a80808, 0.3),
    'linkHover': darken(#a80808, 5%),
    'modalOutline': #e6d6f5,
    'tileGradient': linear-gradient(108.99deg, #409fe4 0%, #49c6e8 49.48%, #5bdced 100%),
    'modalIcons': #a80808,
  ),
);
