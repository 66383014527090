.form-padding {
  padding: 0 16px;
}

.form-container {
  text-align: left;
  margin-bottom: 32px;
  max-width: 100%;
  width: 976px;
  background: $white;
  box-shadow: 0px 24px 48px 0px rgba(0, 0, 0, 0.16);
  border-radius: 1.5rem;
  padding: 1.25rem 1.5rem 2rem;
  position: relative;

  @include breakpoint(0, md) {
    position: relative;
    z-index: 30;
  }

  @include breakpoint(md) {
    background-image: url('../../images/backgrounds/start-playing-box.webp');
    // background-image: url('../../images/backgrounds/enter-box-bg-loveuary-1x.webp');

    object-fit: contain;
    background-repeat: no-repeat;
    background-position: top;
    background-size: 976px;
    box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.16);
    border-radius: 4rem;
    margin-bottom: 80px;
    padding: 32px 100px 48px;
  }

  .form-crown-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    @include breakpoint(md) {
      margin-bottom: 2rem;
    }
  }

  h2 {
    color: theme('primary');
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 110%;
    margin-bottom: 0;
    text-align: center;

    @include breakpoint(md) {
      font-size: 3.5rem;
      margin-bottom: 2.25rem;
    }
  }

  .text-field {
    @include breakpoint(sm, lg) {
      margin-top: 32px;
    }

    @include breakpoint(0, sm) {
      margin-top: 22px;
    }
  }

  .col-12:not(.col-sm-6) .text-field {
    @include breakpoint(xl) {
      margin-top: 32px;
    }
  }

  .col-sm-6 {
    &:nth-of-type(n + 3) {
      @include breakpoint(xl, 0) {
        margin-top: 24px;
      }
    }
  }

  .btn {
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.05rem;
    margin: 0 auto;
    max-height: 56px;
    min-height: 56px;
    max-width: 328px;
    margin-top: 1.5rem;

    @include breakpoint(md) {
      font-size: 1.5rem;
      letter-spacing: 0.075rem;
      max-height: 72px;
      min-height: 72px;
      margin-top: 2rem;
    }
  }

  p {
    a {
      text-decoration: none;
      font-weight: $font-weight-bold;
      color: theme('link');
      text-decoration: underline;
    }
  }
}
