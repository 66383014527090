.disclaimer {
  position: relative;
  z-index: 5;
  margin: 0 auto;
  // max-width: 648px;

  p {
    text-align: center;
    font-size: 10px;

    @include breakpoint(0, sm) {
      text-align: left;
    }

    a {
      color: #292929;
      text-decoration: underline;
    }
  }
}
