.share-container {
  background-color: white;
  border-radius: 1rem;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  padding: 2rem 0 2rem 1rem;

  @include breakpoint(lg) {
    padding: 2rem 1rem;
  }

  .col-12 {
    display: flex;
    align-items: center;
    z-index: 2;
    flex-direction: column;
    padding-right: 0;
    @include breakpoint(md) {
      flex-direction: row;
    }
  }
  .share-header-copy {
    color: theme('primary');
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: 1rem;

    @include breakpoint(md) {
      font-size: 1.2rem;
      margin-bottom: 0rem;
      margin-right: 0.5rem;
    }
  }
}
.MuiButtonBase-root.MuiButton-root.ShareBtn {
  background-color: red;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  font-size: 1.5rem;
  margin-left: 0.6rem;

  &:first-of-type {
    @include breakpoint(0, sm) {
      margin-left: 0;
    }
  }
  &:hover,
  &:active {
    box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.3);
  }
  svg {
    height: 25px;
    width: 25px;
  }
  &.TwitterBtn {
    background-color: #000000;
  }
  &.FacebookBtn {
    background-color: #4267b2;
  }
  &.PinterestBtn {
    background-color: #e60023;
  }
}
