@import '../../../styles/utilities/variables.scss';
@import '../../../styles/utilities/mixins.scss';
@import '../../../styles/utilities/breakpoints.scss';

.landing_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @include breakpoint(0, sm) {
    background-size: 800px auto, cover;
  }

  @include breakpoint(0, lg) {
    padding: 0 16px;
  }

  @include breakpoint(md, 0) {
    background-size: 100% auto, cover;
  }

  .jm-logo-landing-page {
    @include breakpoint(0, sm) {
      position: relative !important;
      top: 0 !important;
      right: 0px !important;
      display: flex !important;
      text-align: center;
      justify-content: center;
      height: 100% !important;

      img {
        width: 10rem;
      }

      p {
        height: 70px !important;
        line-height: 70px !important;
        padding-right: 0.5rem;
        font-size: 0.9rem !important;
      }
    }
    @include breakpoint(md) {
      display: none;
    }
  }

  &.game_landing-header {
    h2 {
      max-width: 600px;
      @include breakpoint(lg, 1279.98) {
        max-width: 700px;
      }

      @include breakpoint(1440, 0) {
        max-width: 700px;
      }
    }
  }

  .hallmark-logo {
    @include breakpoint(0, xs) {
      display: none;
    }

    @include breakpoint(sm, lg) {
      width: 130px;
      height: 99.41px;
    }

    @include breakpoint(xl, 0) {
      width: 250px;
      height: 71.47px;
      margin: 24px 0;
    }
  }

  .jm-logo-mobile {
    display: flex;
    align-items: center;
    gap: 10px;
    text-transform: uppercase;
    letter-spacing: 0.07em;
    padding: 16px 0 40px;

    p {
      margin: 0;
      color: #000;
      font-weight: $font-weight-medium;
      line-height: 100%;
    }

    @include breakpoint(lg, 0) {
      display: none;
    }
  }

  .stacked-logo {
    height: auto;

    @include breakpoint(0, xs) {
      width: 200px;
    }

    @include breakpoint(sm, lg) {
      width: 260px;
      margin-bottom: 24px;
    }

    @include breakpoint(xl, 0) {
      width: 364px;
      margin-bottom: 40px;
    }
  }

  h2 {
    max-width: 1120px;
    font-size: 32px;

    @include breakpoint(0, lg) {
      max-width: 80vw;
    }

    @include breakpoint(0, md) {
      font-size: 20px;
    }

    br {
      @include breakpoint(0, xs) {
        display: none;
      }
    }
  }

  .landing-header-cta {
    position: relative;

    @include breakpoint(0, xs) {
      width: 100%;
    }

    .btn {
      @include breakpoint(0, xs) {
        width: 100%;
      }
    }

    p a {
      text-decoration: underline !important;
    }

    &.landing-header-cta-text {
      margin: 10px 0 70px;
      @include breakpoint(md) {
        margin: 20px 0 100px;
      }

      a {
        pointer-events: none;
        cursor: default;

        .btn {
          opacity: 0;
          pointer-events: none;

          &:not(:disabled):not(.disabled) {
            cursor: default;
          }
        }
      }
    }

    &.landing-header-cta-btn {
      transform: translate(0, 20%);
      @include breakpoint(sm) {
        transform: translate(0, 50%);
      }

      h1 {
        pointer-events: none;
        opacity: 0;
      }
      button {
        margin: 0 auto;
      }
    }
  }

  h1 {
    pointer-events: none;
    position: absolute;
    top: 0;

    left: 50%;
    transform: translate(-50%, 0);

    @include breakpoint(0, xs) {
      font-size: 1.1rem;
      width: 100%;
    }

    @include breakpoint(sm, md) {
      width: 500px;
    }

    @include breakpoint(md, lg) {
      width: 570px;
    }

    @include breakpoint(xl, 0) {
      width: 710px;
    }
  }

  .landing-header__checkboxes {
    width: 884px;
    max-width: 100%;

    @include breakpoint(md) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 525px;
    }

    @include breakpoint(lg) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 461px;
    }
  }

  .landing-header-steps {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 24px;
    margin: 24px 0 40px;

    @include breakpoint(sm) {
      gap: 32px;
    }

    @include breakpoint(md) {
      margin-top: 70px;
    }

    @include breakpoint(lg) {
      gap: 64px;
    }

    &.landing-header-steps-checkboxes {
      @include breakpoint(md) {
        margin-top: 200px;
      }
    }

    .landing-header-step {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      gap: 8px;

      @include breakpoint(md) {
        flex-direction: row;
        gap: 12px;
      }

      h5 {
        color: theme('primary');
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 0.875rem;
        font-weight: 700;
        margin: 0 !important;

        @include breakpoint(md) {
          font-size: 1.5rem;
          line-height: 120%;
          gap: 8px;

          flex-direction: row;
        }

        .bold {
          font-size: 1.5rem;
          line-height: 120%;
        }
      }
    }
  }

  .hallmark-crown-container {
    margin-bottom: 18px;

    @include breakpoint(md) {
      margin-bottom: 26px;
    }
  }

  .landing-header-subtitle {
    color: theme('primary');
    font-size: 1rem;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 1.25rem;

    @include breakpoint(md) {
      font-size: 1.75rem;
      line-height: 120%;
      margin-bottom: 2rem;
    }
  }

  .landing-header-title {
    color: theme('primary');
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 110%;
    margin-bottom: 1.25rem;

    @include breakpoint(md) {
      font-size: 3.5rem;
      font-weight: 700;
      line-height: 110%;
      margin-bottom: 1.5rem;
    }
  }

  .landing-header-return-daily {
    color: #be5427;
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    line-height: 110%;
    margin-bottom: 1.5rem;

    @include breakpoint(md) {
      font-size: 2.25rem;
    }
  }

  .landing-header-awarded {
    color: #657b65;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 110%;
    margin-bottom: 1.5rem;

    @include breakpoint(md) {
      font-size: 1.125rem;
      margin-bottom: 0;
    }
  }

  .landing-checkbox {
    margin-top: 0 !important;
  }

  .landing-checkbox-mobile {
    margin-top: 0 !important;

    @include breakpoint(md) {
      display: none;
    }
  }

  .landing-checkbox-desktop {
    display: none;
    position: relative;
    // top: 60px;
    @include breakpoint(md) {
      display: block;
      margin-top: 60px !important;
    }
  }
}

.logo-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 24px 0 15px;
}

.landing-header-sponsor-logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @include breakpoint(sm) {
    top: -10px;
  }

  @include breakpoint(lg) {
    position: absolute;
    right: -240px;
    top: -40px;
    bottom: 0;
  }

  &.horizontal {
    margin-bottom: 10px;

    @include breakpoint(sm) {
      right: -250px;
    }
  }

  &.stacked {
    display: none;
    position: absolute;
    right: 0;
    top: -170px;

    @include breakpoint(md) {
      display: flex;
    }

    @include breakpoint(xl) {
      top: -236px;
      gap: 1rem;
    }
  }

  p {
    color: theme('primary');
    font-size: 0.875rem;
    margin: 0;

    @include breakpoint(xl) {
      font-size: 1.125rem;
    }
  }

  img {
    max-width: 100px;

    @include breakpoint(md) {
      max-width: 180px;
    }

    @include breakpoint(lg) {
      max-width: 200px;
    }

    @include breakpoint(xl) {
      max-width: 220px;
    }

    &.stacked {
      display: none;
      max-width: 100px;

      @include breakpoint(md) {
        display: block;
      }

      @include breakpoint(xl) {
        max-width: 148px;
      }
    }

    &.horizontal {
      display: block;

      @include breakpoint(md) {
        display: none;
      }
    }
  }
}

.sponsor-logo-label {
  color: theme('primary');
  font-size: 0.65rem;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  letter-spacing: 0.085em;

  @include breakpoint(sm) {
    font-size: 0.85rem;
    letter-spacing: 0.125em;
  }

  @include breakpoint(md) {
    font-size: 1rem;
  }
}

.sponsor-logo-label-small {
  color: theme('primary');
  font-size: 0.7rem;
  font-weight: $font-weight-bold;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 0.085em;
}
