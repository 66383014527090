$almost-black: #333;
$grey-black: #222;
$ctc-blue: #347aa7;
$grey-40: #b1b1b1;
$cotton-candy: #ed98b9;
$candy-cane-kisses: #d33b3b;
$soft-mist: #f6f6f6;

.group {
  h1 {
    font-size: 1.7rem;

    @include breakpoint(xl) {
      font-size: 2.3rem;
    }
  }

  h3 {
    color: #1f4047;
  }

  @include breakpoint(xl) {
    max-width: calc(100vw - 586px);
  }

  .public-group-list-item-container {
    margin: auto;

    @include breakpoint(md) {
      width: 95%;
    }

    [class*='col-'] {
      padding: 0 10px;
    }
  }

  .card-groups {
    flex: 1;
    justify-content: space-between;
    background: $white;
    border: none;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
    border-radius: 1rem;
    padding: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;

    [class*='col-'] {
      padding: 0;
    }

    .btn {
      padding: 12px 20px;
      font-size: 0.8rem;
    }
  }

  .group-name {
    color: theme('primary');
    font-weight: $font-weight-bold;
    font-size: 1.3rem;
    text-align: center;
    line-height: 1.2;
    letter-spacing: 0;
    margin-bottom: 0.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 48px;
  }

  .medal-count {
    color: $ctc-blue;
    margin-right: 1.25rem;
  }

  .medal-data {
    display: flex;
    justify-content: center;
    font-weight: $font-weight-bold;

    img {
      margin-right: 0.5rem;
    }
  }

  .icon-header {
    position: absolute;
    top: -70px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    left: 0;

    .icon {
      max-width: 140px;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
  }

  .button-group {
    display: flex;
    gap: 10px;
    justify-content: space-around;
    margin: 0.5rem 0 0 0;

    @include breakpoint(sm) {
      flex-wrap: nowrap;
    }

    button {
      width: 100%;
    }

    &.button-action {
      .btn {
        font-size: 1rem;
      }
    }

    .view-button {
      background-color: $white;
      border: 2px solid $under-the-mistletoe;
    }
  }

  .ticket-count {
    color: $grey-black;
    display: flex;
    font-size: 1.3rem;
    font-weight: $font-weight-bold;
    justify-content: center;
    align-items: center;
  }
}

.group-header {
  h1 {
    color: theme('primary');
    text-align: left;
    text-transform: capitalize;
    font-size: 1.5rem;

    @include breakpoint(md) {
      font-size: 2.3rem;
    }
  }

  button {
    float: right;
    padding: 0.5rem 0.75rem;

    @include breakpoint(md) {
      padding: 0.5rem 1.25rem;
    }
  }
}

.groups-header {
  h1 {
    color: $under-the-mistletoe;
  }
}

.create-group {
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 14px;
  margin-bottom: 1rem;

  .btn {
    width: 100%;
    max-width: 260px;
  }

  @include breakpoint(md) {
    border-radius: 1rem;
    padding: 0.8rem 0 1rem;
    flex-wrap: nowrap;
  }

  @include breakpoint(xl) {
    justify-content: space-between;
  }

  .create-group-fields {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    width: 100%;

    @include breakpoint(sm) {
      flex-wrap: nowrap;
    }
  }

  .input-label {
    color: $grey-black;
  }

  .text-field-input {
    position: relative;
    width: 100%;
    display: inline-block;

    @include breakpoint(sm) {
      top: -15px;
      margin-bottom: 0;
    }
  }

  .checkbox-container {
    width: 100%;
    text-align: left;
    margin: 0;

    p {
      color: $almost-black;

      span {
        font-weight: $font-weight-bold;
      }
    }

    div {
      line-height: 1;
    }

    .checkmark {
      background-color: $white;
      border-color: $light-gray;
      border-width: 1px;
      margin-top: -3px;
    }
  }
}

.group-header {
  h1 {
    text-align: center;

    @include breakpoint(sm) {
      text-align: left;
    }
  }

  @include breakpoint(0, sm) {
    margin-top: 1rem;
  }

  .view-all-button {
    font-weight: 700;
  }
}

.group-header-closed {
  margin-top: 0;
}

.copy-link {
  display: flex;
  flex-wrap: wrap;

  @include breakpoint(sm) {
    flex-wrap: nowrap;
    margin-bottom: 2rem;
  }

  .share-link__input {
    display: block;
    border-radius: 0.75rem;
    border: 1px solid #b3b3b3;
    padding: 0.75rem 0 0.75rem 1.5rem;
    width: 100%;
    margin-bottom: 1rem;

    @include breakpoint(sm) {
      display: inline;
      border-radius: 0.75rem;
      padding: 0.75rem 0 0.75rem 1.5rem;
      margin-right: 1rem;
      margin-bottom: 0;
    }

    &:focus-visible {
      outline: none;
    }
  }

  .copy-icon {
    text-align: end;
  }

  .btn {
    white-space: nowrap;
    font-size: 1rem;
  }
}

ul.pagination {
  margin-top: 2rem;
  justify-content: center;
}

.page-item {
  height: 50px;
  width: 50px;

  button {
    padding: 0.375rem 0.75rem;
  }

  &.number {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  svg {
    width: 16px;

    path {
      stroke: theme('primary');
    }
  }
}

.page-link {
  background: transparent;
  border-radius: 50% !important;
  height: 100%;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;

  &.next,
  &.previous {
    background-color: transparent;
    border: 1px solid #aaa;
    border-radius: 50% !important;
  }

  &.active {
    background-color: theme('modalIcons') !important;
    border-color: theme('modalIcons') !important;

    p {
      color: $white !important;
      margin-bottom: 0;
    }
  }

  &.btn {
    border: 1px solid #aaa;

    &:hover,
    &:focus,
    &:active {
      p {
        color: $white;
      }
    }
  }

  p {
    margin-bottom: 0;
  }

  .icon-arrow-previous {
    transform: rotate(180deg);
  }

  &:disabled {
    opacity: 0.2;
  }
}

.my-masonry-grid {
  display: flex;
}

.group-detail-page-container {
  .leaderboard-table {
    .table-index {
      width: 5%;

      @include breakpoint(sm) {
        width: 6%;
      }
    }

    .table-name {
      width: 35%;
    }

    .table-points {
      width: 15%;

      @include breakpoint(sm) {
        width: 10%;
      }
    }
  }

  @include breakpoint(xl) {
    max-width: 1220px;
  }

  .btn-primary {
    &:hover {
      opacity: 0.8;
    }
  }

  .back-to-groups-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
    gap: 11px;
    border-radius: 2.125rem;
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    text-transform: uppercase;

    @include breakpoint(0, sm) {
      margin: auto;
    }

    @include breakpoint(0, md) {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 6px;
      padding-bottom: 6px;

      span {
        font-size: 0.7rem !important;
      }
    }

    @include breakpoint(1280, 1452) {
      padding-left: 14px;
      padding-right: 14px;
      padding-top: 10px;
      padding-bottom: 10px;

      .arrow-line-right {
        width: 16px;
      }

      span {
        font-size: 0.8rem !important;
      }
    }

    &:hover {
      &::before {
        background-color: $white;
        left: -8px;
      }
    }

    &::before {
      background-color: $under-the-mistletoe;
      transform-origin: initial;
      top: -2px;
      transition: all 0.3s;

      @include breakpoint(0, sm) {
        top: 0px;
      }
    }
  }

  .private-group-message {
    text-align: center;
    margin: 1rem auto 1rem;
    border-radius: 0.625rem;

    h1 {
      font-size: 1.7rem;
      margin-top: -40px;

      @include breakpoint(sm) {
        font-size: 2rem;
      }

      @include breakpoint(md) {
        font-size: 2.3rem;
      }
    }

    img {
      width: 100%;
      height: auto;
      max-width: 350px;
    }

    @include breakpoint(sm) {
      padding: 2rem;
      max-width: 80%;
      margin: auto;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    p {
      color: #463c24 !important;
      margin: 1.5rem;
    }
  }
}

.group-type-chip {
  background-color: $cotton-candy;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  gap: 6px;
  border-radius: 4.5rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.375rem;
  margin: 1rem;
}

.delete-button {
  max-width: 6.25rem;
  margin: auto;
}

.group-button-container {
  display: flex;

  .btn-danger {
    background-color: $candy-cane-kisses;
    border-color: $candy-cane-kisses;
    border-radius: 2rem;
    text-transform: uppercase;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-weight: bold;
    width: 6.4rem !important;

    @include breakpoint(0, sm) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .view-button-image {
    @include breakpoint(0, sm) {
      margin-right: 0;
      min-width: 1.25rem;
    }
  }
}

.groups-table {
  width: 100%;
  background: $white;
  border-radius: 1rem;
  border: 1px solid #d7d2d2;
  padding: 0;
  margin-bottom: 2rem;

  @include breakpoint(md) {
    margin-bottom: 3rem;
  }

  h3 {
    color: $grey-black;
    font-size: 1.1rem;
    text-transform: capitalize;
    margin-bottom: 0;
    margin-top: 1rem;
    padding-left: 2.5rem;
    padding-bottom: 0.5rem;

    @include breakpoint(0, sm) {
      padding-left: 1.5rem;
    }

    @include breakpoint(sm) {
      font-size: 1.4rem;
    }

    border-bottom: 1px solid rgba(#b5b5b5, 0.3);
  }

  .user-info {
    display: flex;
    align-items: center;
    max-width: calc(100% - 116px);

    @include breakpoint(md) {
      max-width: calc(100% - 244px);
    }
  }

  .entry {
    display: flex;
    padding: 1rem 1.5rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(#b5b5b5, 0.3);

    @include breakpoint(md) {
      padding: 1rem 2.5rem 1rem 2.5rem;
    }

    &:last-of-type {
      border-bottom: 0;
    }

    .group-details {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        margin-right: 10px;
      }
    }

    .group-count {
      color: theme('primary');
      display: flex;
      align-items: center;

      @include breakpoint(sm) {
        width: 70px;
      }
    }
  }

  .greyed {
    background-color: $soft-mist;
  }

  .username {
    font-size: 0.8rem;
    font-weight: $font-weight-bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include breakpoint(md) {
      font-size: 1.1rem;
    }
  }

  .btn {
    padding: 10px 18px;
    font-size: 0.8rem;
  }
}

.group.container {
  @media screen and (min-width: 1480px) {
    max-width: 1050px;
  }
}

.groups-header {
  padding: 2rem 0 1rem 0;
  text-transform: uppercase;

  @include breakpoint(md) {
    padding: 2rem 0;
  }

  h1 {
    color: $white;
    font-size: 1.1rem;

    @include breakpoint(md) {
      font-size: 1.4rem;
    }
  }
}

.groups-table-header {
  padding: 1rem 2.5rem;
  color: #ba0020;
  font-weight: $font-weight-bold;
  font-size: 1.4rem;
  border-bottom: 1px solid rgba(#b5b5b5, 0.3);
}

.page-groups {
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 1rem;

  .pretitle {
    font-size: 1.4rem;
    letter-spacing: normal;
    color: theme('primary');
  }

  @include breakpoint(md) {
    border-radius: 1.5rem;
  }

  .input-label {
    color: theme('primary');
    font-weight: $font-weight-bold;
    text-align: left;
    margin-bottom: 6px;
  }

  .public-groups {
    [class*='col-'] {
      padding: 0 10px;
    }
  }

  .form-control {
    padding: 10px 10px;
    height: auto;
    border-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
}

.invite-link-container {
  border-radius: 1.5rem;
  background: #fff;
  box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.16);
  margin: 2rem auto;
  padding: 2rem;

  @include breakpoint(sm) {
    margin: 3rem auto;
  }

  .btn {
    @include breakpoint(0, xs) {
      width: 100%;
    }
  }
}

.share-link-header {
  font-size: 1.2rem;
  text-align: center;

  @include breakpoint(sm) {
    font-size: 1.5rem;
    text-align: left;
  }
}

.share-link-label {
  text-align: left;
  color: theme('primary');
  font-weight: $font-weight-bold;
  margin-bottom: 6px;
}

.leaderboard-header-joined {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;

  @include breakpoint(md) {
    margin-top: 0;
    margin-bottom: 0;
  }

  h1 {
    font-size: 1.7rem;

    @include breakpoint(md) {
      text-align: center;
    }

    @include breakpoint(xl) {
      font-size: 2.3rem;
    }
  }
}

.group-status-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  @include breakpoint(sm) {
    flex-direction: row;
  }

  @include breakpoint(md) {
    justify-content: flex-end;
  }
}

.group-status {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  gap: 6px;
  font-weight: $font-weight-bold;
  color: theme('primary');
  font-size: 1.1rem;

  @include breakpoint(sm) {
    justify-content: flex-end;
  }
}

.public-group-list-item {
  display: flex;

  @include breakpoint(1400) {
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }

  @include breakpoint(1700) {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.groups-navigation-mobile {
  display: flex;
  align-items: center;

  @include breakpoint(lg) {
    display: none;
  }
}

.groups-navigation-desktop {
  display: none;

  @include breakpoint(lg) {
    display: flex;
    align-items: center;
  }
}
