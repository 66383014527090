$bp-xs-max: 575.98px;
$bp-sm-min: 576px;
$bp-sm-max: 767.98px;
$bp-md-min: 768px;
$bp-md-max: 991.98px;
$bp-lg-min: 992px;
$bp-lg-max: 1199.98px;
$bp-xl-min: 1200px;

@mixin onHover() {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

@mixin onTouch() {
  @media (hover: none) {
    &:hover {
      @content;
    }
  }
}

@function theme($variant: 'primary') {
  $map: map-get($theme-colors, 'default');
  @return map-get($map, $variant);
}

@mixin focus-outline($color, $opacity) {
  box-shadow: 0 0 0 0.2rem $color; /*  Fallback */
  box-shadow: 0 0 0 0.2rem rgba($color, $opacity);
}
