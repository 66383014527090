form {
  .text-field {
    position: relative;
    font-family: $albra;

    .input-label {
      color: theme('primary');
      margin-left: 4px;
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 8px;

      @include breakpoint(md) {
        font-size: 1.125rem;
      }
    }

    input {
      width: 100%;
      background: $white;
      border: 1px solid $input-outline;
      border-radius: 0.5rem;
      box-sizing: border-box;
      color: $text;

      &::placeholder {
        color: $input-placeholder;
        opacity: 1;
      }

      @media (max-width: $bp-md-max) {
        height: 40px;
      }

      @media (min-width: $bp-lg-min) {
        height: 47px;
      }
    }

    .form-control:focus {
      border-color: $input-outline;
      box-shadow: 0 0 0 0.2rem rgba(240, 142, 29, 0.1);
    }

    .form-control.is-invalid,
    .was-validated .form-control:invalid {
      border-color: $hallmark-red;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url('../../images/form-error.svg') !important;
      background-repeat: no-repeat;
      background-position: right 14px center !important;
      background-size: 18px !important;
      border-radius: 0.5rem;
    }

    .form-control.is-valid,
    .was-validated .form-control:valid {
      border-color: $ctc-green;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url('../../images/form-valid.svg') !important;
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center !important;
      background-size: 18px !important;
    }

    .valid-tooltip {
      display: none !important;
    }

    .invalid-tooltip {
      padding: 0.05rem 0.5rem;
      top: 97%;
      border-radius: 0px 0 0.2rem 0.2rem;
    }
  }

  .forgot-password {
    text-align: right;
    font-weight: $font-weight-medium;
    font-size: 13px;
    display: block;
    color: $ctc-green;
    margin-top: 2px;
  }

  .col-sm-12 {
    .text-field {
      @media (min-width: $bp-sm-min) and (max-width: $bp-sm-max) {
        margin: 32px 0 16px;
      }

      @media (min-width: $bp-md-min) and (max-width: $bp-md-max) {
        margin: 32px 0 16px;
      }

      @media (min-width: $bp-lg-min) {
        margin: 32px 0;
      }
    }
  }

  .text-field {
    .select-wrapper {
      position: relative;
      width: 100%;
    }
    select {
      color: #363636;
      font-size: 1rem;
      position: relative;
      padding: 8px 16px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 0.5rem;
      width: 100%;
      background: #fff;
      -moz-appearance: none;
      -webkit-appearance: none;

      @include breakpoint(sm) {
        padding: 12px 16px;
      }
    }

    .select-wrapper::after {
      content: ' ';
      background-image: url('../../images/icons/chevron-down-icon.svg');
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      right: 16px;
      width: 13px;
      height: 8px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: black;

  @include breakpoint(0, xs) {
    margin-bottom: 32px;
  }

  @include breakpoint(xl, 0) {
    margin-bottom: 24px;
  }

  p a {
    transition: none;
    text-decoration: none !important;
  }

  p a:hover {
    @media (hover: hover) {
      text-decoration: underline !important;
      color: darken(theme('link'), 10%) !important;
    }
  }

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: transparent;

      &:after {
        display: block;
      }
    }
  }

  & ~ .error-message {
    left: 50px;
    top: 65px;
  }

  &:first-of-type {
    margin-top: 32px;

    @include breakpoint(md) {
      margin-top: 16px;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    border: 2px solid #0000001f;
    border-radius: 0.25rem;
    background-color: #fff;
    box-sizing: border-box;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 7px;
      top: 0px;
      width: 9px;
      height: 18px;
      border: solid theme('modalIcons');
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  @media (hover: hover) {
    &:hover input[type='checkbox'] ~ .checkmark {
      background: #ee2d241a;
    }
  }

  .invalid-tooltip {
    display: block;
    border-radius: 0px 0 0.2rem 0.2rem;

    @include breakpoint(0, xs) {
      top: calc(100% + 7px);
    }

    @include breakpoint(xl, 0) {
      top: calc(100% + 4px);
    }
  }

  .invalid-tooltip {
    font-size: 11px;
    padding: 1px 6px;
    background-color: $hallmark-red;
    margin: 0;
  }

  &.checkbox-error {
    .checkmark {
      border-color: $hallmark-red;
    }
  }

  &:nth-of-type(2) {
    .invalid-tooltip {
      @include breakpoint(0, 385.98) {
        top: 100%;
        border-radius: 0.2rem;
      }
    }
  }
  &.landing {
    a {
      color: theme('primary');
      text-decoration: underline;
    }
  }
}

.social-login {
  .divider {
    border-bottom: 1px solid $dark-gray;
    flex: 1;
  }

  p {
    margin: 0 8px;
  }

  .FacebookBtn {
    @media (min-width: $bp-sm-min) and (max-width: $bp-sm-max) {
      margin-right: 12px;
    }

    @media (min-width: $bp-md-min) {
      margin-right: 20px;
    }
  }

  .SocialBtn.MuiButtonBase-root {
    .BtnLabel {
      @media (min-width: $bp-sm-min) and (max-width: $bp-sm-max) {
        font-size: 13px;
      }
    }
  }
}

.radio-container {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (max-width: $bp-sm-max) {
    padding-left: 27px;
  }

  @media (min-width: $bp-md-min) {
    padding-left: 35px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      & ~ .radio {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  .radio {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border-radius: 50%;
    border: 3px solid $text;
    box-sizing: border-box;

    &:after {
      top: 1px;
      left: 1px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $text;
      content: '';
      position: absolute;
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }
  }
}

.input-show-error {
  select,
  .form-control {
    border-color: $candy-cane !important;
  }

  .zip-code-container {
    position: relative;
    width: 100%;

    &:before {
      content: ' ';
      position: absolute;
      border-color: $hallmark-red;
      top: 50%;
      transform: translateY(-50%);
      right: 14px;
      background-image: url('../../images/form-error.svg') !important;
      background-size: 18px;
      border-radius: 0.5rem;
      z-index: 1;
      width: 18px;
      height: 18px;
    }
  }

  .select-wrapper {
    &:before {
      content: ' ';
      position: absolute;
      border-color: $hallmark-red;
      top: 50%;
      transform: translateY(-50%);
      right: 40px;
      background-image: url('../../images/form-error.svg') !important;
      background-size: 18px;
      border-radius: 0.5rem;
      z-index: 1;
      width: 18px;
      height: 18px;
    }
  }

  .invalid-tooltip {
    display: block;
  }
}
