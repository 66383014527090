.btn {
  font-family: $albra;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  line-height: 100%;
  letter-spacing: 0.04em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 200ms ease-in-out;
  cursor: pointer;

  &.btn-arrow.btn-icon-before {
    svg {
      transform: rotate(180deg);
    }
  }

  .arrow-line-right {
    height: 18px;
    width: 18px;

    @include breakpoint(md) {
      height: 24px;
      width: 24px;
    }
  }

  .btn-loader {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    border-top: 4px solid rgba(255, 255, 255, 0.2);
    border-right: 4px solid rgba(255, 255, 255, 0.2);
    border-bottom: 4px solid rgba(255, 255, 255, 0.2);
    border-left: 4px solid $white;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    transition: opacity 300ms ease-in;

    &:after {
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }

    @-webkit-keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    &.btn-loader-visible {
      opacity: 1;
    }

    &.btn-loader-hidden {
      opacity: 0;
    }
  }
}

.btn-sm {
  line-height: 100%;
  border-radius: 300px;
  padding: 12px 24px;

  @include breakpoint(0, 349.98) {
    font-size: 15px;
  }

  @include breakpoint(350, 0) {
    font-size: 18px;
  }

  svg {
    height: 12px;
    width: 6.86px;
  }

  &.btn-icon-after {
    svg {
      margin-left: 16px;
    }
  }

  &.btn-icon-before {
    svg {
      margin-right: 16px;
    }
  }
}

.btn-lg {
  border-radius: 1.75rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  min-height: 56px;
  width: 100%;

  @include breakpoint(md) {
    border-radius: 2.5rem;
    font-size: 1.5rem;
    letter-spacing: 0.075rem;
    min-height: 72px;
  }

  svg {
    @include breakpoint(0, sm) {
      height: 12px;
      width: 6.86px;
    }

    @include breakpoint(md, 0) {
      height: 20px;
      width: 11.44px;
    }
  }

  &.btn-icon-after {
    svg {
      margin-left: 16px;
    }
  }
}

.btn-content {
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  margin-bottom: -3px;

  &.btn-content-visible {
    opacity: 1;
  }

  &.btn-content-hidden {
    opacity: 0.3;
  }
}

.btn-primary {
  $btn-primary-main: theme('primary');
  $btn-primary-hover: theme('primaryHover');

  background: $btn-primary-main;
  border-color: $btn-primary-main;
  text-align: center;
  color: $white;
  transform: scale(1);

  &:not(:disabled):not(.disabled) {
    &:hover {
      color: $white;
      background-color: darken($btn-primary-main, 5%);
      border-color: darken($btn-primary-main, 5%);
    }
  }

  &.disabled,
  &:disabled {
    background-color: $btn-primary-main;
    border-color: $btn-primary-main;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  &:focus {
    color: $white;
    background-color: darken($btn-primary-main, 10%);
    border-color: darken($btn-primary-main, 10%);
    box-shadow: none !important;
  }

  &.focus,
  &:focus {
    @include focus-outline($btn-primary-main, 0.3);
  }

  &.leaderboard {
    background-color: theme('modalIcons');
    border-color: theme('modalIcons');
    text-align: center;
    color: $white;
  }
}

.btn-outline-primary {
  $btn-primary-main: theme('primary');
  $btn-primary-hover: theme('primaryHover');

  background: $white;
  border-color: $btn-primary-main;
  text-align: center;
  color: $btn-primary-main;
  transform: scale(1);

  .arrow-line-right {
    path {
      stroke: $btn-primary-main;
    }
  }

  &:focus {
    box-shadow: none !important;

    .arrow-line-right {
      path {
        stroke: $white;
      }
    }
  }

  &:not(:disabled):not(.disabled) {
    &:hover {
      color: $white;
      background-color: darken($btn-primary-main, 5%);
      border-color: darken($btn-primary-main, 5%);

      svg {
        path {
          fill: $white;
          stroke: $white;
        }
      }
    }
  }

  &.leaderboard {
    $btn-primary-main: theme('modalIcons');
    $btn-primary-hover: theme('modalIcons');
    background: $white;
    border-color: theme('modalOutline');
    color: theme('primary');
  }

  &.disabled,
  &:disabled {
    background-color: $white;
    border-color: $btn-primary-main;
    color: $btn-primary-main;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  &:focus {
    $btn-primary-main: theme('primary');
    $btn-primary-hover: theme('primary');

    color: $white;
    background-color: darken($btn-primary-main, 10%);
    border-color: darken($btn-primary-main, 10%);
  }

  svg {
    path {
      fill: transparent !important;
    }
  }
}

.btn-link {
  $btn-link-main: theme('link');
  $btn-link-hover: theme('linkHover');
  padding: 0;
  font-size: 15px;
  background: transparent;
  border: none;
  text-align: center;
  color: $btn-link-main;
  transform: scale(1);
  text-transform: initial;
  font-weight: $font-weight-book;
  letter-spacing: 0.02em;

  &:not(:disabled):not(.disabled) {
    &:hover {
      background: transparent;
      color: $btn-link-hover;
    }
  }

  &.disabled,
  &:disabled {
    background: transparent;
    color: $btn-link-main;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  &:focus {
    background: transparent;
    color: $btn-link-main;
    box-shadow: none !important;
  }

  &.focus,
  &:focus {
    @include focus-outline($btn-link-main, 0.3);
  }
}

.btn-tutorial-exit {
  $btn-primary-main: theme('primary');
  $btn-primary-hover: theme('primaryHover');

  background: transparent;
  border-color: transparent;
  text-align: center;
  color: $btn-primary-main;
  transform: scale(1);

  @include breakpoint(0, xs) {
    margin-top: 8px;
  }
  @include breakpoint(sm, 0) {
    margin-top: 0.5rem;
  }

  &:not(:disabled):not(.disabled) {
    &:hover {
      color: $white;
      background-color: $btn-primary-main;
      border-color: transparent;

      svg {
        path {
          fill: $white;
        }
      }
    }
  }

  svg {
    height: 12px;
    width: 12px;
  }

  &.disabled,
  &:disabled {
    background-color: $white;
    border-color: transparent;
    color: $btn-primary-main;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  &:focus {
    color: $white;
    background-color: $btn-primary-main;
    border-color: transparent;
    box-shadow: none !important;
  }

  &.focus,
  &:focus {
    @include focus-outline($btn-primary-main, 0.3);
  }

  svg {
    path {
      fill: $btn-primary-main;
    }
  }
}

.btn-form-append {
  text-align: center;
  color: $white;
  transform: scale(1);
  letter-spacing: 0;
  text-transform: uppercase;

  @include breakpoint(0, xs) {
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 300px;
  }

  @include breakpoint(sm, sm) {
    padding: 8px 20px;
    font-size: 14px;
  }

  @include breakpoint(md, 0) {
    padding: 8px 20px;
    font-size: 18px;
  }

  &:not(:disabled):not(.disabled) {
    &:hover {
      color: $white;
    }
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  &:focus {
    color: $white;
    box-shadow: none !important;
  }

  svg {
    @include breakpoint(0, xs) {
      display: none;
    }

    @include breakpoint(sm, sm) {
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
    @include breakpoint(md, 0) {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
}

.nav-btn-profile {
  font-size: 14px;
  text-transform: none;
  display: block;
  white-space: nowrap;
  letter-spacing: 0.01rem;
  font-weight: 500;
  margin-left: 0.75rem;
  &-link {
    color: theme('primary');
    text-decoration: underline;
    font-weight: 700;
    .btn-content-visible svg {
      margin-left: 5px;
      path {
        fill: theme('primary');
      }
    }
  }
}

.btn-logout-text {
  color: theme('primary');
  text-decoration: underline;
}
