.tooltip {
  &.show {
    opacity: 1;
  }

  .tooltip-inner {
    background-color: theme('link');
    color: $white;
    font-family: $albra;
  }

  &.bs-tooltip-auto[x-placement^='bottom'],
  &.bs-tooltip-bottom {
    margin-top: 8px;

    .arrow::before {
      border-bottom-color: theme('link');
    }
  }

  &.bs-tooltip-auto[x-placement^='top'],
  &.bs-tooltip-top {
    margin-bottom: 8px;

    .arrow::before {
      border-top-color: theme('link');
    }
  }
}
