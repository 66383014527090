@import '../../../styles/utilities/variables.scss';
@import '../../../styles/utilities/mixins.scss';
@import '../../../styles/utilities/breakpoints.scss';

.nav-bar-mobile {
  border-top: 2px solid rgba(0, 0, 0, 0.12);
  min-width: 100%;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  display: flex;
  z-index: 100;
  background: white;

  @include breakpoint(lg) {
    display: none;
  }
}

.nav-bar-mobile-link {
  position: relative;
  width: 25%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  padding: 10px 0;

  @include breakpoint(sm) {
    width: 120px;
  }

  &:last-child {
    border-right: none;

    @include breakpoint(380) {
      border-right: 2px solid rgba(0, 0, 0, 0.12);
    }
  }

  &:first-child {
    border-left: none;

    @include breakpoint(380) {
      border-left: 2px solid rgba(0, 0, 0, 0.12);
    }
  }

  &.active {
    svg {
      path {
        fill: url(#gradient);
      }
    }
  }

  svg {
    @include breakpoint(sm) {
      width: 30px;
      height: 30px;
    }
  }
}

.nav-bar-mobile-link-title {
  color: theme('primary');
  font-size: 12px;
  font-weight: 700;
  line-height: 130%;

  @include breakpoint(sm) {
    font-size: 16px;
  }
}

.nav-bar-mobile-indicator {
  position: absolute;
  top: -1.5px;
  background: linear-gradient(270deg, #bc0f8b 0.01%, #ffbc3a 100.01%);
  box-shadow: 0px -1px 4px 0px rgba(223, 106, 97, 0.64);
  height: 2px;
  width: 100%;
}
