.sponsor-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 2px;

  @include breakpoint(sm) {
    top: 3px;
  }

  img {
    max-width: 86px;
  }

  &.stacked {
    flex-direction: column;
  }

  &.sponsor-logo-label-small {
    font-size: 0.5625rem;
  }

  &.sponsor-logo-mobile {
    @include breakpoint(1600) {
      display: none;
    }
  }
}

.sponsor-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  margin-bottom: 24px;

  @include breakpoint(lg) {
    display: none;
  }

  p {
    color: #333;
    font-size: 1rem;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }

  img {
    max-width: 86px;
  }
}

.sponsor-desktop {
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &-sm {
    @include breakpoint(sm) {
      display: flex;
    }
  }
  &-md {
    @include breakpoint(md) {
      display: flex;
    }
  }
  &-lg {
    @include breakpoint(lg) {
      display: flex;
    }
  }
  &-xl {
    @include breakpoint(xl) {
      display: flex;
    }
  }
  &-xxl {
    @include breakpoint(xxl) {
      display: flex;
    }
  }

  p {
    color: #333;
    font-size: 1rem;
    font-weight: 700;
    line-height: 100%;
    text-align: center;
    margin: 0;
    white-space: nowrap;
  }

  img {
    max-width: 75px;

    @include breakpoint(1400) {
      max-width: 100px;
    }
  }
}
