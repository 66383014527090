.game {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  width: 100%;

  @include breakpoint(0, xs) {
    flex-direction: column;
    margin: 12px 0 24px;
  }

  @include breakpoint(sm, lg) {
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 24px auto 0 auto;
    max-width: calc(100vw - 32px);
  }

  @include breakpoint(xl, 0) {
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 24px 0 0 0;
  }

  @include breakpoint(1200, 1279.98) {
    max-width: 900px;
  }

  @include breakpoint(1280, 0) {
    max-width: calc(100vw - 648px);
  }

  .game-container {
    width: 100%;
    position: relative;
    z-index: 10;

    canvas {
      margin-left: 50%;
      transform: translateX(-50%);
    }

    &.game-container-with-help {
      @include breakpoint(0, xs) {
        min-height: 560px;
      }
      @include breakpoint(sm, sm) {
        min-height: 630px;
      }
    }

    @include breakpoint(0, 767.98) {
      margin: 0 auto;
    }

    @include breakpoint(768, 0) {
      margin: 0 auto;
    }

    @include breakpoint(0, xs) {
      order: 2;
    }
  }

  .game-overlay-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoint(0, xs) {
      padding-top: 75px;
    }
    @include breakpoint(sm, sm) {
      padding-top: 100px;
    }

    @include breakpoint(md, 0) {
      padding-top: 120px;
    }
    @include breakpoint(sm, 0) {
      flex-direction: column;
    }

    .interactive-game-tutorial {
      display: flex;
      align-items: center;

      @include breakpoint(0, sm) {
        flex-direction: column;
      }

      .interactive-game-tutorial-step {
        display: flex;
        align-items: center;
      }

      ol {
        list-style: none;
        counter-reset: list-counter;
        margin-bottom: 0;
        @include breakpoint(0, sm) {
          margin-right: calc(40px + 1em - 26px - 8px);
        }

        li {
          p {
            @include breakpoint(0, xs) {
              font-size: 12px;
              margin-bottom: 8px;
            }

            @include breakpoint(md, lg) {
              font-size: 14px;
            }
          }
        }

        li:nth-of-type(-n + 3) {
          counter-increment: list-counter;
          position: relative;

          &.interactive-game-tutorial-step-active {
            &::before {
              transform: scale(1.4);
              opacity: 1;
            }
          }

          &::before {
            content: counter(list-counter);
            color: $white;
            font-weight: bold;
            background: theme('gradient');
            position: absolute;
            top: 0;
            opacity: 0.5;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: scale(1);
            transition: all 200ms ease-in;

            @include breakpoint(0, xs) {
              width: 16px;
              height: 16px;
              right: calc(100% + 8px);
              font-size: 12px;
            }

            @include breakpoint(sm, 0) {
              width: 24px;
              height: 24px;
              right: calc(100% + 12px);
            }
          }
        }
      }

      .interactive-game-tutorial-step-img {
        position: relative;
        img {
          height: auto;
          transition: all 200ms ease-in;

          @include breakpoint(0, xs) {
            width: 240px;
          }

          @include breakpoint(sm, sm) {
            width: 350px;
          }

          @include breakpoint(md, md) {
            width: 280px;
          }

          @include breakpoint(lg, 0) {
            width: 350px;
          }

          &:nth-of-type(n + 2) {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }

  .game-nav-left,
  .game-nav-right {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 15;

    @include breakpoint(0, xs) {
      justify-content: space-around;
      width: 100%;
    }
  }

  .game-nav-left {
    position: relative;
    @include breakpoint(0, xs) {
      order: 1;
    }

    @include breakpoint(xl, 0) {
      padding: 0 16px 0 0;
    }

    .game-nav__btn {
      padding-right: 0;
    }
    .banner-new {
      position: absolute;
      background: theme('primary');
      top: -16px;
      color: #fff;
      padding: 0 0.25rem;
      text-transform: uppercase;
      font-size: 9px;
      font-weight: 500;
      height: 16px;
      line-height: 18px;
      left: 0;
      @include breakpoint(sm) {
        height: 18px;
        top: -18px;
        font-size: 10px;
        padding: 0 0.5rem;
      }
      @include breakpoint(1600) {
        height: 20px;
        line-height: 22px;
        top: -20px;
        padding: 0 1rem;
        font-size: 12px;
        font-weight: 700;
      }
    }
  }

  .game-nav-right {
    @include breakpoint(0, xs) {
      order: 3;
      max-width: 360px;
      margin: 0 auto;
    }

    @include breakpoint(xl, 0) {
      padding: 0 0 0 16px;
    }

    @include breakpoint(1500, 0) {
      min-width: calc(100% - 400px);
      justify-content: flex-end;
    }

    & > button:nth-of-type(1) {
      padding-left: 0;
    }

    .game-nav__btn {
      display: flex;
      align-items: center;
      padding-right: 0;

      &:focus {
        box-shadow: none;

        .game-nav__btn-icon {
          @include focus-outline(theme('primary'), 0.3);
        }
      }

      &:hover {
        .game-nav__btn-icon {
          transform: scale(1.05);
        }
      }

      &:active {
        .game-nav__btn-icon {
          transform: scale(0.95);
        }
      }
    }
  }

  .game-nav__level {
    background: theme('primary');
    border-radius: 4px;
    font-family: $albra;
    font-weight: $font-weight-bold;
    color: $white;
    text-transform: uppercase;
    &.new {
      border-top-left-radius: 0;
      position: relative;
    }

    @include breakpoint(0, sm) {
      font-size: 12px;
      padding: 2px 6px;
    }

    @include breakpoint(md, 1198.98) {
      font-size: 18px;
      padding: 2px 8px;
    }

    @include breakpoint(1200, 1499.98) {
      font-size: 12px;
      padding: 2px 6px;
    }

    @include breakpoint(1500, 0) {
      font-size: 18px;
      padding: 2px 8px;
    }
  }

  .game-nav__btn {
    display: flex;
    align-items: center;

    &.disabled,
    &:disabled {
      opacity: 0.5;
    }

    &.game-nav__btn-img-hide {
      .btn-content > img {
        opacity: 0;
      }
    }

    &.game-nav__btn-img-show {
      .btn-content > img {
        opacity: 1;
      }
    }

    .btn-content > img {
      position: absolute;
      left: 50%;
      transition: all 200ms ease-in;
      pointer-events: none;
      transform-origin: 50% 50%;

      @include breakpoint(0, xs) {
        transform: rotate(180deg) translate(50%, 0);
        bottom: 100%;
        animation: arrowHintDown 2s infinite ease-in-out;
        @keyframes arrowHintDown {
          0% {
            transform: rotate(180deg) translate(50%, 0) scale(1);
          }

          10% {
            transform: rotate(180deg) translate(50%, 0) scale(1.1);
          }

          20% {
            transform: rotate(180deg) translate(50%, 0) scale(1);
          }
          30% {
            transform: rotate(180deg) translate(50%, 0) scale(1.1);
          }

          40% {
            transform: rotate(180deg) translate(50%, 0) scale(1);
          }

          100% {
            transform: rotate(180deg) translate(50%, 0) scale(1);
          }
        }
      }

      @include breakpoint(sm, 0) {
        transform: translate(-50%, 0);
        top: 100%;
        animation: arrowHintUp 2s infinite ease-in-out;
        @keyframes arrowHintUp {
          0% {
            transform: translate(-50%, 0) scale(1);
          }

          10% {
            transform: translate(-50%, 0) scale(1.1);
          }

          20% {
            transform: translate(-50%, 0) scale(1);
          }
          30% {
            transform: translate(-50%, 0) scale(1.1);
          }

          40% {
            transform: translate(-50%, 0) scale(1);
          }

          100% {
            transform: translate(-50%, 0) scale(1);
          }
        }
      }
    }

    &:not(button) {
      cursor: default !important;
    }

    @include breakpoint(0, sm) {
      padding: 0.1rem 0.2rem;
    }

    @include breakpoint(md, 0) {
      padding: 0.1rem 0.5rem;
    }

    .game-nav__btn-icon {
      background: theme('primary');
      border-radius: 50%;
      position: relative;
      z-index: 5;
      transition: all 200ms ease-in;

      @include breakpoint(0, xs) {
        width: 34px;
        height: 34px;
      }

      @include breakpoint(sm, 629.98) {
        width: 30px;
        height: 30px;
      }

      @include breakpoint(630, 0) {
        width: 32px;
        height: 32px;
      }

      @include breakpoint(1500, 0) {
        width: 40px;
        height: 40px;
      }

      img {
        width: 100%;
      }
    }

    .game-nav__btn-content {
      background: $white;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
      color: theme('primary');
      font-family: $albra;
      font-weight: $font-weight-bold;

      @include breakpoint(0, sm) {
        margin-left: -15px;
        font-size: 12px;
        padding: 4px 8px 4px 17px;
        border-radius: 4px;
      }

      @include breakpoint(md, lg) {
        margin-left: -20px;
        font-size: 20px;
        padding: 8px 14px 8px 24px;
        border-radius: 8px;
      }

      @include breakpoint(1200, 1499.98) {
        margin-left: -16px;
        font-size: 14px;
        padding: 4px 4px 4px 20px;
        border-radius: 4px;
      }

      @include breakpoint(1500, 0) {
        margin-left: -20px;
        font-size: 20px;
        padding: 8px 14px 8px 24px;
        border-radius: 8px;
      }

      &.game-nav__btn-content_timer {
        @include breakpoint(0, sm) {
          width: 68px;
        }
        @include breakpoint(md, 1198.98) {
          width: 90px;
        }
        @include breakpoint(1200, 1499.98) {
          width: 64px;
        }
        @include breakpoint(1500, 0) {
          width: 90px;
        }
      }

      &.game-nav__btn-content_shuffles,
      &.game-nav__btn-content_hints {
        @include breakpoint(0, sm) {
          width: 38px;
        }
        @include breakpoint(md, 1198.98) {
          width: 54px;
        }
        @include breakpoint(1200, 1499.98) {
          width: 38px;
        }
        @include breakpoint(1500, 0) {
          width: 54px;
        }
      }
    }
  }
}

.game-start-modal-logo {
  width: 270px;
  max-width: 100%;
}
