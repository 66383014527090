$text-placeholder: #b0aeae;

$ctc-green: #218649;
$ctc-evergreen: #2a5037;
$ctc-red: #e21219;
$ctc-gold: #fcd574;

$berry-much-in-love: #802595;
$mint-to-be: #0e5432;
$gingerbread-joy: #d7b76e;
$candy-cane: #ee2d24;

$spring-fling-candy: #fb7fb5;
$spring-fling-cyan: #4fc6db;
$spring-fling-grass: #82bc00;
$spring-fling-grass-dark: #578000;
$spring-fling-sunshine: #ffde17;

$spring-fling-candy-light: lighten($spring-fling-candy, 20%);
$spring-fling-candy-hover: darken($spring-fling-candy, 10%);
$spring-fling-cyan-hover: darken($spring-fling-cyan, 10%);

$under-the-mistletoe: #205b42;
$plum: #652b82;

$fall-harvest-header-bg: #c05411;
$christmas-header-bg: #c05411;

$text-darkgreen: #293c03;

$gray: #7c7c7c;
$light-gray: #c4c4c4;
$dark-gray: #b8b8b8;

$secondary: $ctc-green;
$input-label: $mint-to-be;
$input-placeholder: #b8b8b8;

$secondary-hover: darken($secondary, 10%);
$white-hover: darken($white, 10%);

$facebook-blue: #3b5998;
$google-blue: #4285f4;
$twitter-blue: #1b95e0;
$pinterest-red: #bd081c;
$email-green: #82bc00;

$facebook-blue-hover: darken($facebook-blue, 10%);
$google-blue-hover: darken($google-blue, 10%);
$twitter-blue-hover: darken($twitter-blue, 10%);
$pinterest-red-hover: darken($pinterest-red, 10%);
$email-green-hover: darken($email-green, 10%);

$sm-button-shadow: 0px 6px 20px rgba(186, 0, 32, 0.3);
$md-button-shadow: 0px 7px 23px rgba(186, 0, 32, 0.3);
$lg-button-shadow: 0px 6px 34px rgba(186, 0, 32, 0.3), 0px 4px 9px rgba(0, 0, 0, 0.1);

$xs-button-radius: 8px;
$sm-button-radius: 4px;
$xl-button-radius: 8px;

$table-shadow: 0px -4px 15px rgba(98, 11, 11, 0.1);
$table-outline: #b9c8c2;

$gold-gradient: linear-gradient(180deg, #f8e0a2 0%, #c6a24d 100%);

$facebook-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1), 0px 1px 14px rgba(66, 103, 178, 0.4);
$twitter-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 14px rgba(27, 149, 224, 0.4);
$pinterest-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1), 0px 0px 10px rgba(189, 8, 28, 0.2);
$email-shadow: 0px 0px 4px rgba(60, 134, 7, 0.4), 0px 4px 9px rgba(0, 0, 0, 0.1);
