.top-decorations {
  height: 14px;
  width: 100%;
  background-image: url('../../images/chex2023/top-decoration-bar-2x.webp');
  background-position: center;
  object-fit: cover;
  position: relative;

  @include breakpoint(md) {
    height: 32px;
  }

  .top-decoration-left {
    display: none;
    content: ' ';
    position: absolute;
    left: 0;
    top: 10px;
    height: 176px;
    width: 176px;
    background-image: url('../../images/chex2023/top-decoration-heart-left-2x.webp');
    background-position: center;
    background-repeat: no-repeat;
    object-fit: contain;
    background-size: 100% 100%;

    @include breakpoint(md) {
      display: block;
    }
  }

  .top-decoration-right-1 {
    display: none;
    content: ' ';
    position: absolute;
    top: 32px;
    height: 154px;
    width: 170px;
    background-image: url('../../images/chex2023/top-decoration-heart-3-2x.webp');
    background-position: center;
    background-repeat: no-repeat;
    object-fit: contain;
    background-size: 100% 100%;
    z-index: 1;

    @include breakpoint(md) {
      display: block;
      right: 10px;
    }

    @include breakpoint(lg) {
      right: 45px;
    }
  }

  .top-decoration-right-2 {
    display: none;
    content: ' ';
    position: absolute;
    top: 30px;
    height: 176px;
    width: 176px;
    background-image: url('../../images/chex2023/top-decoration-heart-2-2x.webp');
    background-position: center;
    background-repeat: no-repeat;
    object-fit: contain;
    background-size: 100% 100%;
    z-index: 1;

    @include breakpoint(md) {
      display: block;
      right: 75px;
    }

    @include breakpoint(lg) {
      right: 125px;
    }
  }
}
