@import '../../../styles/utilities/variables.scss';
@import '../../../styles/utilities/mixins.scss';
@import '../../../styles/utilities/breakpoints.scss';

.nav-bar {
  background-color: $white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
  flex-direction: column;

  @include breakpoint(0, lg) {
    flex-wrap: wrap;
  }
  @include breakpoint(xl) {
    flex-direction: row;
    padding: 0 32px;
    max-height: 80px;
  }

  &.nav-bar-with-sns {
    @include breakpoint(md, lg) {
      padding-bottom: 0;
    }

    &.nav-bar-logged-out {
      .nav-bar_sns {
        @include breakpoint(0, xs) {
          width: 80px;
        }

        @include breakpoint(0, lg) {
          order: 1;
          position: absolute;
          top: 0;
          left: 0;
          height: calc(100% - 1px);
        }
      }

      .horizontal-logo {
        @include breakpoint(0, xs) {
          max-width: calc(100vw - 160px);
          height: auto;
        }
      }
    }
  }

  &.nav-bar-logged-out {
    justify-content: center;

    .nav-bar_logo {
      margin-bottom: 0 !important;
    }

    .nav-bar_logout {
      padding: 0 !important;
    }

    &.nav-bar-with-countdown {
      @include breakpoint(0, xs) {
        padding-bottom: 8px;
      }
    }
  }

  &.nav-bar-logged-in {
    justify-content: space-between;

    &:not(.nav-bar-with-countdown):not(.nav-bar-with-entries):not(.nav-bar-with-sns) {
      @include breakpoint(sm, lg) {
        flex-wrap: nowrap;

        .nav-bar_logo {
          border-bottom: none;
          width: auto;
        }

        .nav-bar_logout {
          padding-left: 0;
        }
      }

      .nav-bar_logout {
        @include breakpoint(0, xs) {
          width: 100%;
          justify-content: center;
        }

        p {
          @include breakpoint(sm, sm) {
            max-width: calc(100vw - 420px);
          }
        }
      }
    }
  }

  &.nav-bar-with-entries {
    &:not(.nav-bar-with-countdown) {
      @include breakpoint(lg, lg) {
        padding-bottom: 0;

        .nav-bar_entries {
          order: 1;
        }

        .nav-bar_logout {
          order: 3;

          p {
            max-width: calc(100% - 80px);
          }
        }

        .nav-bar_entries,
        .nav-bar_logout {
          width: calc(50vw - 190px);
        }
      }
    }
  }

  &:not(.nav-bar-with-countdown) {
    .nav-bar_entries-desktop {
      @include breakpoint(0, lg) {
        display: none;
      }
    }
  }

  &.nav-bar-with-countdown {
    @include breakpoint(0, xs) {
      .nav-bar_entries-desktop .nav-bar_entries {
        display: none;
      }
    }

    @include breakpoint(sm, lg) {
      .nav-bar_entries-desktop {
        display: none;
      }
    }

    @include breakpoint(0, sm) {
      .nav-bar_entries_container {
        flex-direction: column;
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
      }

      &:not(.nav-bar-with-entries) {
        .nav-bar_logout {
          padding-top: 12px;
          width: 100%;
          justify-content: center;

          p {
            max-width: calc(100vw - 140px);
          }
        }
      }
    }

    @include breakpoint(sm, lg) {
      .nav-bar_entries,
      .nav-bar_logout {
        padding-top: 8px;
        padding-bottom: 0;
      }
    }
  }

  &.nav-bar-with-countdown,
  &.nav-bar-with-entries {
    @include breakpoint(0, lg) {
      .nav-bar_entries_container {
        padding-bottom: 0;
      }
      .nav-bar_entries {
        padding-left: 12px;
        order: 2;
      }
    }

    @include breakpoint(sm, lg) {
      padding-bottom: 8px;
    }

    @include breakpoint(xl, 0) {
      .nav-bar_entries {
        margin-right: 12px;
      }
    }
  }

  &:not(.nav-bar-with-countdown) {
    @include breakpoint(0, sm) {
      .nav-bar_entries_container {
        padding-left: 12px;
      }
    }
  }

  .nav-bar_logout {
    padding: 12px 16px;

    @include breakpoint(xl) {
      padding: 0 16px;
    }
  }

  .nav-bar_entries,
  .nav-bar_logout {
    display: flex;
    align-items: center;

    p,
    a {
      @include breakpoint(0, lg) {
        font-size: 14px;
      }
    }

    p {
      margin-bottom: 0;

      @include breakpoint(0, lg) {
        margin-right: 4px;
      }

      @include breakpoint(md, 0) {
        margin-right: 8px;
      }
    }
  }

  .nav-bar_entries_container {
    display: none;

    @include breakpoint(lg) {
      align-items: center;
      display: flex;
      margin-left: 1.5rem;
    }
  }

  .nav-bar_entries {
    @include breakpoint(xl, 0) {
      justify-content: flex-start;
    }

    & > p {
      font-weight: $font-weight-medium;
      margin-left: 4px;
      margin-right: 0px;
      line-height: 100%;

      .uppercase {
        font-size: 12px;
        font-weight: $font-weight-medium;
        color: theme('primary');
      }
    }

    .nav-bar_entries-number {
      border-radius: 50%;
      background: theme('gradient');
      position: relative;

      @include breakpoint(0, sm) {
        width: 24px;
        height: 24px;
      }

      @include breakpoint(md, 0) {
        width: 32px;
        height: 32px;
      }

      p {
        position: absolute;
        color: $white;
        font-weight: $font-weight-bold;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .nav-bar-entries-counter {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-right: 16px;

    @include breakpoint(md) {
      gap: 12px;
    }

    div {
      font-size: 1rem;
      margin: 0;
      padding: 0;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: theme('modalIcons');
      border-radius: 100%;
      color: $white;
      font-weight: bold;
      width: 25px;
      height: 25px;
      min-width: 25px;
      min-height: 25px;

      @include breakpoint(md) {
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
      }

      &.text-base {
        font-size: 14px;
        @include breakpoint(md) {
          font-size: 16px;
        }
      }
      &.text-sm {
        font-size: 12px;
        @include breakpoint(md) {
          font-size: 14px;
        }
      }
      &.text-xs {
        font-size: 10px;
        @include breakpoint(md) {
          font-size: 12px;
        }
      }
    }
  }

  .nav-bar_logo-mobile {
    display: flex;
    gap: 16px;
    max-width: 400px;

    @include breakpoint(0, md) {
      max-width: 300px;
    }

    svg {
      width: 100%;
      max-width: 200px;
    }

    .mm-logo-stacked {
      @include breakpoint(1600) {
        display: none;
      }
    }

    .sponsor-desktop {
      @include breakpoint(0, md) {
        display: none;
      }
    }
  }

  .nav-bar_logo-desktop {
    display: flex;
    max-width: 424px;
    display: none;
    gap: 15px;

    svg {
      width: 100%;
    }

    @include breakpoint(1600) {
      display: flex;
    }
  }

  .nav-bar_countdown-tablet {
    @include breakpoint(sm, lg) {
      margin-left: 24px;
    }
  }

  .nav-bar_logout {
    .username {
      color: theme('primary');
      font-weight: 700;
    }
    @include breakpoint(0, lg) {
      order: 3;
      padding-right: 12px;
    }

    justify-content: flex-end;

    a {
      font-weight: $font-weight-bold;
      cursor: pointer;
    }

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include breakpoint(0, sm) {
        max-width: calc(100vw - 230px);
      }

      @include breakpoint(sm, lg) {
        max-width: 300px;
      }

      @include breakpoint(xl, 0) {
        max-width: calc(100% - 100px);
      }
    }

    .btn-sm {
      padding: 8px 10px;
    }
  }

  .nav-bar_entries-mobile {
    @include breakpoint(xl, 0) {
      display: none;
    }
  }

  .nav-btn-profile {
    display: none;
    background: $white;
    border: 1.5px solid theme('primary');
    border-radius: 1.25rem;
    font-size: 0.75rem;
    letter-spacing: 0.0375rem;
    text-transform: uppercase;
    font-weight: bold;
    color: theme('primary');

    @include breakpoint(lg) {
      display: flex;
    }
  }
}

.nav-bar_left {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-height: 90px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0 15px;

  @include breakpoint(lg) {
    justify-content: space-between;
  }

  @include breakpoint(xl) {
    justify-content: center;
    max-height: unset;
    width: unset;
    border: none;
    padding: 0;
  }
}

.nav-bar_menu {
  display: flex;
  align-items: center;
  margin-left: 20px;
  div:last-child {
    border-right: 2px dotted #d9eafe;
  }
}

.nav-bar_menu-item {
  position: relative;
  color: theme('primary');
  text-transform: uppercase;
  word-wrap: break-word;
  padding: 29px 16px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  font-weight: 700;
  transition: none;

  @include breakpoint(sm) {
    font-size: 0.9rem;
    letter-spacing: 0.04rem;
  }

  @include breakpoint(1400) {
    font-size: 1.125rem;
    line-height: 120%;
    letter-spacing: 0.05625rem;
    padding: 32px;
  }

  &:hover span {
    background: var(--Gradient, linear-gradient(311deg, #bb0f8b 0%, #ffbc39 85.51%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:last-child {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }
}

.nav-bar_menu-item-indicator {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(270deg, #bc0f8b 0.01%, #ffbc3a 100.01%);
  box-shadow: 0px -1px 4px 0px rgba(223, 106, 97, 0.64);
  width: 100%;
  height: 4px;
}

.nav-bar_menu-item--active span {
  background: var(--Gradient, linear-gradient(311deg, #bb0f8b 0%, #ffbc39 85.51%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav-bar_sns {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 52.77%, #ffffff 97.19%),
    url('../../../images/backgrounds/background-people.svg'), url('../../../images/backgrounds/background-fair.svg'),
    linear-gradient(90deg, rgba(255, 255, 255, 0) 52.77%, #ffffff 97.19%), #d9eafe;
  background-size: 100% auto, 100% auto, cover, cover;
  background-position: center bottom, center bottom, center center, center center;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  padding: 4px 12px;

  @include breakpoint(0, sm) {
    position: relative;
    order: 3;
    height: 37px;
    width: 120px;
  }

  @include breakpoint(md, lg) {
    order: 3;
    height: 40px;
  }

  @include breakpoint(xl, 0) {
    position: absolute;
    left: 0;
    height: calc(100% - 1px);
  }

  @include onHover {
    img {
      transform: scale(1.05);
    }
  }

  img {
    height: 100%;

    width: auto;
    transform: scale(1);
    transition: all 250ms ease-in;
  }
}
.earned-badge-container {
  position: relative;
  margin-left: 0.5rem;

  span {
    position: absolute;
    margin: 0 auto;
    left: 0;
    top: 0;
    text-align: center;
    color: #fff;
    font-weight: 900;
    right: 0;
    bottom: 0;
    line-height: 1.8;
    font-size: 1rem;

    @include breakpoint(sm) {
      font-size: 1.2rem;
    }
  }
  .icon-shield {
    max-height: 27px;
    @include breakpoint(sm) {
      max-height: 100%;
    }
  }
}
.profile-picture {
  border-radius: 50%;
  margin-left: 0.5rem;
  position: relative;
  background-color: #652b82;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    border: 1px solid #652b82;
  }
  img {
    max-height: 25px;
    max-width: 25px;
    image-rendering: auto;
    @include breakpoint(sm) {
      max-height: 30px;
      max-width: 30px;
    }
  }
}

.navbar-sponsor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 30px;

  a {
    line-height: 1;
  }

  @include breakpoint(lg) {
    display: none;
  }

  p {
    font-size: 0.6875rem;
    font-weight: 400;
    color: theme('primary');
    margin: 0;
  }

  img {
    max-width: 64px;
  }
}

.navbar-sponsor-desktop {
  display: none;

  @include breakpoint(lg) {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 6px;
    margin-left: 20px;
  }

  p {
    font-size: 0.875rem;
    color: #333;
    margin-bottom: 2px;
  }

  img {
    max-width: 140px;
  }
}

.nav-bar-link {
  @include breakpoint(0, lg) {
    width: 100%;
  }
}
