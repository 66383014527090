$bp-xs-max: 575.98px;
$bp-sm-min: 576px;
$bp-sm-max: 767.98px;
$bp-md-min: 768px;
$bp-md-max: 991.98px;
$bp-lg-min: 992px;
$bp-lg-max: 1199.98px;
$bp-xl-min: 1200px;
$bp-xxl-max: 1825.99px;
$bp-xxl-min: 1826px;

@mixin breakpoint($min: 0, $max: 0) {
  @if type-of($min) == string or type-of($max) == string {
    $query: 'all' !default;
    $minBreakpoints: (
      sm: $bp-sm-min,
      md: $bp-md-min,
      lg: $bp-lg-min,
      xl: $bp-xl-min,
      xxl: $bp-xxl-min,
    );
    $maxBreakpoints: (
      xs: $bp-xs-max,
      sm: $bp-sm-max,
      md: $bp-md-max,
      lg: $bp-lg-max,
      xxl: $bp-xxl-max,
    );

    // if both values are strings, get breakpoint from array
    @if type-of($min) == string and type-of($max) == string {
      $query: '(min-width: #{map-get($minBreakpoints, $min)}) and (max-width: #{map-get($maxBreakpoints, $max)})';
    } @else if type-of($min) == string and type-of($max) == number and $max == 0 {
      $query: '(min-width: #{map-get($minBreakpoints, $min)})';
    } @else if type-of($min) == string and type-of($max) == number and $max != 0 {
      $query: '(min-width: #{map-get($minBreakpoints, $min)}) and (max-width: #{$max}px)';
    } @else if type-of($min) == number and $min == 0 and type-of($max) == string {
      $query: '(max-width: #{map-get($maxBreakpoints, $max)})';
    } @else if type-of($min) == number and $min != 0 and type-of($max) == string {
      $query: '(min-width: #{$min}px) and (max-width: #{map-get($maxBreakpoints, $max)})';
    }

    @media #{$query} {
      @content;
    }

    // if neither is a string, they're both numbers
  } @else {
    $query: 'all' !default;

    @if $min == 0 {
      $query: '(max-width: #{$max}px)';
    } @else if $max == 0 {
      $query: '(min-width: #{$min}px)';
    } @else if $min != 0 and $max != 0 {
      $query: '(min-width: #{$min}px) and (max-width: #{$max}px)';
    }

    @media #{$query} {
      @content;
    }
  }
}
