.isIE {
  #content-wrap {
    display: block;
  }

  .page-background .halftone {
    display: none;
  }

  .sweepstakes-callout svg {
    display: none;
  }

  .sweepstakes-callout_content {
    text-align: center;
  }

  .modal-callout.modal-tune-in-full img {
    margin-bottom: -2.6em;
  }

  .btn .btn-loader {
    top: calc(50% - 15px);
    left: calc(50% - 15px);
  }

  .share-link__field {
    width: 100%;
    margin: 32px auto 0 auto;
    max-width: 650px;

    .share-link__input {
      width: calc(100% - 250px);
    }

    .btn-form-append {
      width: 250px;
    }
  }

  .disclaimer {
    @include breakpoint(xl, 0) {
      width: 1140px;
    }
  }

  .page-background-game.page-background {
    display: block;
  }
}
