.game-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
  width: 1210px;
  max-width: 100%;
  padding: 0 15px;

  @include breakpoint(md) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.game-navigation-left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 1.5rem;

  .navigation__level {
    padding: 0.25rem 1rem;
    background: #a91e82;
    border-radius: 0.25rem;
    color: $white;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.05rem;
    text-transform: uppercase;

    @include breakpoint(340px) {
      font-size: 1rem;
    }

    @include breakpoint(md) {
      font-size: 1.5rem;
      letter-spacing: 0.075rem;
    }
  }

  .navigation__time-score-container {
    display: flex;
    align-items: center;
    column-gap: 1.5rem;

    @include breakpoint(md) {
      column-gap: 1.35rem;
    }
  }

  .navigation__time-score {
    display: flex;
    align-items: center;
    gap: 0.75rem;

    img {
      width: 29px;
      height: 29px;
      box-shadow: 0px 6px 12px 0px rgba(240, 142, 29, 0.32);
      border-radius: 100%;

      @include breakpoint(md) {
        width: 32px;
        height: 32px;
      }
    }

    span {
      font-size: 1.25rem;
      font-weight: 700;

      @include breakpoint(md) {
        font-size: 1.5rem;
      }
    }
  }

  .navigation__score {
    position: relative;
    right: 3px;
  }

  .navigation__time-value {
    width: 46px;
    text-align: left;

    @include breakpoint(md) {
      width: 55px;
    }
  }
}

.game-navigation-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 1px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.12);
  background: rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;

  @include breakpoint(md) {
    height: 56px;
  }

  .navigation-right__button {
    display: flex;
    position: relative;
    background: $white;
    border-radius: 0;
    padding: 0.75rem 1.5rem;
    flex: 1;
    height: 100%;

    &:hover {
      background: darken($white, 10%);
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:focus {
      box-shadow: none;
    }

    @include breakpoint(xl) {
      padding: 0.75rem 1rem;
    }

    .btn-content {
      align-items: center;
      justify-content: center;
      gap: 0.75rem;
    }

    .navigation-button__text {
      display: none;
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: 0.0375rem;
      text-transform: uppercase;
      white-space: nowrap;
      text-align: left;

      @include breakpoint(xl) {
        display: inline;
      }
    }

    .navigation-button__value {
      position: absolute !important;
      top: 4px !important;
      right: 4px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      color: $white !important;
      background: theme('modalIcons') !important;
      border-radius: 100% !important;
      width: 16px !important;
      height: 16px !important;
      font-size: 0.6875rem !important;
      font-weight: 600 !important;
      line-height: 1 !important;
      letter-spacing: normal !important;
      text-align: center !important;

      @include breakpoint(xl) {
        position: relative !important;
        top: 0 !important;
        right: 0 !important;
        font-size: 0.875rem !important;
        width: 24px !important;
        height: 24px !important;
      }

      span {
        position: relative;
        top: 1px;
        left: 0.5px;

        @include breakpoint(sm) {
          top: 0;
          left: 0;
        }
      }
    }

    @include breakpoint(md) {
      padding: 1rem 1.5rem;
    }
  }

  .navigation-right__icon {
    width: 24px;
    height: 24px;
  }
}
