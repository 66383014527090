.swal2-container {
  .swal2-popup {
    overflow: hidden;
    border-radius: 0 !important;
  }
  &:after {
    content: '';
    width: calc(100% - 15px);
    left: 7.5px;
    position: absolute;
    top: 7.5px;
    height: calc(100% - 15px);
    border: 2px dashed #e6d6f5;
    pointer-events: none;
    @include breakpoint(sm) {
      top: 5px;
      left: 5px;
      height: calc(100% - 10px);
      width: calc(100% - 10px);
      border-radius: 5px;
    }
  }
  .swal2-popup {
    flex-direction: column !important;
    align-items: flex-start !important;
    box-shadow: 0 0 0.625em rgba(0, 0, 0, 0.05) !important;
    background-color: #fff !important;
    padding: 1em !important;

    .swal2-header {
      .swal2-title {
        margin-left: 0;
        color: red;
      }
    }

    .swal2-content {
      font-family: $albra;
      .swal2-html-container {
        display: flex !important;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      .badge-image {
        max-height: 120px;
        padding: 0.25rem;
        @include breakpoint(md) {
          max-height: 150px;
          padding: 0.75rem;
        }
      }
      p {
        color: theme('primary');
        text-transform: uppercase;
        font-weight: 900;
        width: 90%;
        text-align: left;
        line-height: 1.2;
        font-size: 1.2rem;
        margin-right: 1rem;
        max-width: 300px;
        @include breakpoint(md) {
          max-width: 220px;
        }
      }
    }
  }

  .swal2-image {
    margin-top: 0;
  }
  &.swal2-top-end {
    bottom: 0 !important;
    top: auto !important;
    padding: 0 !important;
    border-radius: 0 !important;
    left: 0 !important;
    @include breakpoint(sm) {
      bottom: auto !important;
      top: 10px !important;
      left: auto !important;
      right: 10px !important;
    }
  }
}

.swal2-timer-progress-bar-container {
  background: rgb(175, 175, 175) !important;

  .swal2-timer-progress-bar {
    background: rgb(255, 255, 255);
    position: absolute !important;
    right: 0 !important;
  }
}

.swal-popup-hide {
  opacity: 0 !important;
  transform: translateY(-100%) !important;
  animation: all 0.5s ease-in !important;
}

.swal-popup-show {
  opacity: 1 !important;
  transform: translateY(0) !important;
  animation: all 0.5s ease-in !important;
}
.swal2-actions {
  font-size: 12px !important;
  justify-content: flex-start !important;
  margin: 0 !important;
  display: none;
  button.swal2-styled {
    border-radius: 1rem;
    background-color: theme('primary');
    text-transform: uppercase;
    font-weight: 900;
    padding: 0.25rem 1.25rem !important;
    margin: 0 !important;
  }
}
.swal2-html-container {
  .swal2-actions {
    display: flex;
  }
}
