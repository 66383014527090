.hallmark-crown-container {
  display: flex;
  align-items: center;
  gap: 16px;

  .hallmark-crown-line {
    height: 1px;
    width: 36px;
    background-color: theme('modalIcons');

    @include breakpoint(md) {
      top: 34px;
      gap: 24px;
      width: 94px;
    }
  }

  .hallmark-crown-line--sweepstakes {
    background: linear-gradient(270deg, #d09d58 0.54%, #86582f 50.52%, #f7dd8a 100%);
  }

  .hallmark-crown {
    max-width: 33px;

    @include breakpoint(md) {
      max-width: 50px;
    }
  }
}
