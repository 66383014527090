.error {
  margin: 20vh auto;
  position: relative;
  z-index: 5;
  text-align: center;
  flex: 1;

  .btn-primary {
    margin: 24px auto 0 auto;
  }
}
