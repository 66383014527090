.page-landing {
  &.game_page-landing {
    @include breakpoint(0, xs) {
      padding-bottom: 80px;
    }

    @include breakpoint(sm) {
      padding-bottom: 80px;
    }

    .col-12.order-md-2 {
      @include breakpoint(lg, 0) {
        display: flex;
        align-items: center;
        max-width: 500px;
      }
    }

    [class*='col-'] {
      & > h4 {
        @include breakpoint(0, sm) {
          margin: 8px 0 24px 0;
        }
      }
    }
  }
}

.landing-container {
  @include breakpoint(0, sm) {
    margin-top: 35px;
  }

  @include breakpoint(md, md) {
    margin-top: 100px;
  }

  @include breakpoint(lg, 0) {
    margin-top: 90px;
  }

  .row {
    align-items: center;
  }

  .landing-container_image {
    width: 100%;
    position: relative;
    order: 1;

    @include breakpoint(sm, sm) {
      max-width: 400px;
      margin: 0 auto;
    }

    img {
      width: 100%;
      position: relative;
      right: 120px;
      width: 654px;

      @include breakpoint(0, lg) {
        width: 100%;
        right: unset;
      }
    }

    svg {
      width: calc((110 / 664) * 100%);
      height: auto;
      position: absolute;
      top: calc((344 / 431) * 100%);
      left: calc((250 / 664) * 100%);
    }
  }

  .ribbon {
    display: flex;
    justify-content: center;
    @include breakpoint(0, sm) {
      justify-content: center;
    }
    &.header {
      h4 {
        padding: 0.5em 3rem;
        font-weight: 900;
        font-size: 18px;
      }
    }
    h4 {
      text-transform: uppercase;
      color: $white;
      line-height: 100%;
      margin-bottom: 0;
      letter-spacing: 0.08em;
      padding: 0.5em 1em;
      position: relative;
      background: theme('ribbonBg');
      width: 388px;

      @include breakpoint(0, sm) {
        width: 224px;
      }

      z-index: 1;

      &::after {
        content: '';
        position: absolute;
        width: calc(100% + 2px);
        height: 100%;
        top: 0;
        left: -1px;
        background: url('../../images/backgrounds/ribbon-bg_left.svg'),
          url('../../images/backgrounds/ribbon-bg_center.svg'), url('../../images/backgrounds/ribbon-bg_right.svg');
        background-position: left top, center top, right top;
        background-size: 5% 100%, 92% 100%, 5% 100%;
        background-repeat: no-repeat, no-repeat, no-repeat;
        z-index: -1;
      }

      @include breakpoint(0, md) {
        font-size: 16px;
      }
    }
    &.whats-new {
      display: flex;
      justify-content: center;
    }
  }

  [class*='col-'] {
    & > h4 {
      @include breakpoint(0, sm) {
        margin: 24px 0 24px 0;
      }

      @include breakpoint(md, md) {
        margin: 12px 0;
        font-size: 1.2rem;
      }

      @include breakpoint(lg, 0) {
        margin: 24px 0;
        font-size: 30px;
        text-align: left;
      }
    }

    & > p.light {
      text-align: left;
      @include breakpoint(0, sm) {
        font-size: 12px;
        margin-bottom: 30px;
      }

      @include breakpoint(md, md) {
        font-size: 13px;
      }
    }
  }

  .landing_tiles-container {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-bottom: 0;

    @include breakpoint(md) {
      flex-direction: row;
      gap: 32px;
      margin-bottom: 7rem;
    }

    @include breakpoint(lg) {
      margin-bottom: 5rem;
    }

    @include breakpoint(xl) {
      position: relative;
      left: 70px;
      margin-bottom: 0;
    }
  }

  .landing_tiles-text {
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    order: 2;
    max-width: 434px;

    @include breakpoint(lg) {
      margin-bottom: 100px;
      margin-left: 30px;
    }

    h4 {
      width: 100%;
      text-align: center;
      color: theme('primary');
      margin: 0 0 1rem !important;
      font-size: 1.75rem;
      font-weight: 700;
      line-height: 110%;

      @include breakpoint(sm) {
        font-size: 2.25rem;
      }

      @include breakpoint(md) {
        text-align: left;
        margin-bottom: 0 0 2.5rem !important;
        font-size: 3rem;
        font-weight: 700;
        line-height: 110%;
      }
    }

    p {
      color: #333;
      font-size: 1rem;
      line-height: 140%;
      text-align: center;
      width: 100%;

      @include breakpoint(md) {
        font-size: 1.375rem;
        text-align: left;
      }

      a {
        color: #a91e82;
        text-decoration: underline;
      }
    }

    @include breakpoint(md, 0) {
      padding: 0;
    }
  }

  .landing_tiles-disclaimer {
    margin: 0 auto;

    @include breakpoint(md) {
      margin: 0;
    }
  }

  a {
    text-decoration: underline;
  }
}

.sweepstakes_page-landing .landing-container {
  margin-top: 0;
}

.landing-with-checkboxes-container {
  @include breakpoint(0, md) {
    margin-top: 125px;
  }

  @include breakpoint(0, xs) {
    margin-top: 75px;
  }

  .landing_tiles-container {
    margin-top: 20px;

    @include breakpoint(md) {
      margin-top: 140px;
    }
  }
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .leaf-1 {
    position: absolute;
    left: 10%;
    top: 5%;
    @include breakpoint(0, xs) {
      left: -16%;
      top: 2%;
      max-width: 30px;
    }
    @include breakpoint(sm) {
      max-width: 50px;
      left: -10%;
    }
    @include breakpoint(xl) {
      max-width: 100%;
    }
  }
  .leaf-2 {
    position: absolute;
    right: -30%;
    top: 38%;
    @include breakpoint(0, xs) {
      max-width: 40px;
      top: 50%;
    }
    @include breakpoint(sm) {
      max-width: 50px;
      top: 60%;
    }
    @include breakpoint(xl) {
      max-width: 100%;
    }
  }
  .leaf-3 {
    position: absolute;
    left: -40%;
    top: 50%;
    @include breakpoint(0, xs) {
      max-width: 40px;
      left: -30%;
    }
    @include breakpoint(sm) {
      max-width: 50px;
      left: -30%;
      top: 60%;
    }
    @include breakpoint(xl) {
      max-width: 100%;
    }
  }
}
.leaf-4,
.leaf-5,
.leaf-6,
.leaf-7 {
  position: absolute;
}
.leaf-4 {
  bottom: -20px;
  right: -100px;
  @include breakpoint(0, xs) {
    bottom: -50px;
    left: 20px;
    max-width: 50px;
    right: unset;
  }
  @include breakpoint(sm) {
    bottom: -270px;
    right: -10px;
    max-width: 60px;
  }
  @include breakpoint(md) {
    bottom: -20px;
  }
  @include breakpoint(xl) {
    max-width: 100%;
  }
}
.leaf-5 {
  top: -50px;
  @include breakpoint(0, xs) {
    top: 120px;
    right: 10px;
    max-width: 50px;
  }
  @include breakpoint(sm) {
    top: 130px;
    left: 10px;
    max-width: 70px;
    right: unset;
  }
  @include breakpoint(md) {
    left: -80px;
    top: -20px;
  }
  @include breakpoint(xl) {
    max-width: 100%;
    right: -180px;
    top: -20px;
    left: unset;
  }
}
.leaf-6 {
  top: -100px;
  right: 110px;
  @include breakpoint(0, xs) {
    bottom: -200%;
    right: 30px;
    max-width: 50px;
    top: unset;
  }
  @include breakpoint(sm) {
    right: 10px;
    max-width: 55px;
  }
  @include breakpoint(xl) {
    max-width: 100%;
  }
}
.leaf-7 {
  left: -130px;
  top: -60px;
  display: none;
  @include breakpoint(md) {
    display: inline;
    left: -50px;
    max-width: 50px;
  }
  @include breakpoint(xl) {
    max-width: 80px;
  }
}

.bg-image {
  position: absolute;
  &.tree {
    display: none;
    top: 50vh;
    @include breakpoint(sm) {
      display: block;
      max-width: 100px;
      right: -10px;
    }
    @include breakpoint(lg) {
      right: -20px;
      max-width: 150px;
    }
    @include breakpoint(1400) {
      right: -40px;
      max-width: 100%;
    }
  }
  &.present {
    display: none;
    @include breakpoint(sm) {
      display: block;
      right: -50px;
      top: -70px;
      max-width: 200px;
    }
    @include breakpoint(lg) {
      right: -0px;
      top: -20px;
      max-width: 220px;
    }
    @include breakpoint(1400) {
      top: -100px;
      max-width: 100%;
    }
  }
}

.row-new {
  margin: 2rem -15px 1rem;
  @include breakpoint(sm) {
    margin: 2rem -15px 4rem;
  }
  .card-1 {
    padding-right: 7.5px;
    @include breakpoint(sm) {
      padding-right: 15px;
    }
  }
  .card-2 {
    padding-left: 7.5px;
    @include breakpoint(sm) {
      padding-left: 15px;
    }
  }
  .card {
    border: none;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;
  }
  .card-text {
    color: #802595;
    text-transform: uppercase;
    font-weight: 900;
    text-align: center;
    line-height: 1.2;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    padding: 0 0.25rem;
    @include breakpoint(sm) {
      font-size: 11px;
      padding: 0 0.75rem;
    }
    @include breakpoint(xl) {
      font-size: 12px;
      padding: 0 0.75rem;
    }
  }
}
.asterix {
  font-size: 20px;
  line-height: 27px;
  vertical-align: top;
}
.modal-new-badge {
  padding-top: 0;
  .modal-newbadge-image {
    animation: pop-in 1s forwards;
    opacity: 0;
    animation-delay: 0.25s;
    margin-top: -10px;
    max-height: 180px;
    @include breakpoint(sm) {
      max-height: 220px;
    }
    @include breakpoint(md) {
      max-height: 270px;
    }
  }
}

.enter-now-box {
  background: $white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  border-radius: 1.5rem;
  box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.16);
  width: 1180px;
  max-width: 100%;
  padding: 24px 16px 32px;

  @include breakpoint(md) {
    border-radius: 4rem;
    min-height: 400px;
    margin: 0 16px;
    padding: 18px 16px 85px;
  }

  @include breakpoint(lg) {
    min-height: 335px;
    background-image: url('../../images/backgrounds/enter-box-bg.webp');
    object-fit: cover;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top;
    background-size: 1180px;
  }

  .enter-now-box-copy {
    max-width: 680px !important;
  }

  &.enter-now-box.enter-now-box-sweepstakes {
    position: relative;
    gap: 0;
    margin-bottom: 40px;

    @include breakpoint(md) {
      margin-bottom: 84px;
    }

    @include breakpoint(lg) {
      min-height: 399px;
    }

    .landing-checkbox p {
      margin-bottom: 0;
      text-align: left;
    }
  }

  h2 {
    color: theme('primary');
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
    line-height: 120%;
    margin: 0;

    @include breakpoint(md) {
      font-size: 3rem;
      line-height: 110%;
      max-width: 600px !important;
    }

    @include breakpoint(lg) {
      max-width: 980px !important;
    }
  }

  .landing-enter-now-signed-in,
  .landing-enter-now-signed-out {
    max-width: 200px;

    @include breakpoint(md) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -36px;
      margin-bottom: 0;
      max-width: 292px;
      width: 292px;
    }
  }

  .landing-enter-now {
    @include breakpoint(md) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -36px;
      margin-bottom: 0;
    }

    .btn {
      background-color: $mint-to-be;
      border-color: $mint-to-be;
      font-size: 1rem;
      font-weight: bold;
      letter-spacing: 0.05rem;
      max-height: 56px;
      min-height: 56px;
      width: 292px;

      margin-bottom: 1rem;

      &:hover {
        background-color: darken($mint-to-be, 5%);
        border-color: darken($mint-to-be, 5%);
      }

      @include breakpoint(md) {
        font-size: 1.5rem;
        letter-spacing: 0.075rem;
        max-height: 72px;
        min-height: 72px;
        max-width: 292px;
        margin-bottom: 0;
      }
    }
  }
}
