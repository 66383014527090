.profile-page {
  max-width: 800px;
  display: flex;
  margin: auto;
}

.profile-container {
  h1 {
    text-align: center;
    text-transform: none;
    color: theme('primary');
  }
}

.profile-header {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  margin-bottom: 1rem;
  background-color: #fff;
  position: relative;
  padding: 1rem;
  border-radius: 1.5rem;
  box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.16);
  z-index: 1;

  @include breakpoint(sm) {
    padding: 2rem;
  }

  .profile-header-container {
    display: flex;
    justify-content: center;

    @include breakpoint(md) {
      justify-content: space-between;
      flex-direction: row;
    }
  }

  .profile-pic img {
    max-width: 80px;
  }

  .profile-name {
    color: theme('primary');
    font-weight: 700;
    font-size: 1.2rem;

    @include breakpoint(md) {
      font-size: 1.6rem;
    }

    @include breakpoint(lg) {
      font-size: 3rem;
    }
  }

  .profile-user,
  .profile-details {
    gap: 10px;
  }

  .profile-user {
    justify-content: center;

    @include breakpoint(md) {
      justify-content: flex-start;
    }
  }

  .profile-decorative {
    @include breakpoint(sm) {
      position: relative;
      margin-bottom: -85px;
      z-index: 0;
    }
  }

  .earned-badge-container {
    margin: 0;

    span {
      font-size: 1.5rem;

      @include breakpoint(sm) {
        font-size: 1.8rem;
      }
    }

    .icon-shield {
      width: 44px;
      max-height: 40px;

      @include breakpoint(sm) {
        max-height: 100%;
      }
    }
  }

  .hallmark-crown {
    max-width: 400px;
  }

  .profile-header-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoint(lg) {
      flex-direction: row;
      justify-content: space-between;
      max-height: 100%;
    }
  }
}

.profile-container {
  margin-bottom: 2rem;
  position: relative;
  padding-bottom: 2rem;
  width: 100%;

  @include breakpoint(sm) {
    margin-bottom: 4rem;
  }
}

.profile-stat-container {
  width: 100%;
  height: 70px;
  border-radius: 8px;
  display: flex;

  @include breakpoint(sm) {
    height: 114px;
  }

  @include breakpoint(1280) {
    height: 95px;
  }

  @include breakpoint(1350) {
    height: 114px;
  }
}

.profile-stats {
  position: relative;
  background-color: #fffbfa;
  border-radius: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 1;
  margin-top: 1rem;

  @include breakpoint(sm) {
    padding: 0 20px;
  }

  .row {
    @include breakpoint(0, sm) {
      margin: 0;
      flex-direction: column;
    }
  }
}

.profile-stat {
  text-align: left;
  padding-left: 0.5rem;
  display: flex;
  flex-direction: column;
  margin: auto 0;

  @include breakpoint(sm) {
    padding-left: 0.8rem;
  }

  h4 {
    font-weight: 700;
    font-size: 12px;
    line-height: 130%;
    color: theme('primary');
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: 0;

    @include breakpoint(sm) {
      font-size: 12px;
    }

    @include breakpoint(1280) {
      font-size: 12px;
    }

    @include breakpoint(1420) {
      font-size: 12px;
    }
  }

  h5 {
    font-weight: 700;
    font-size: 10px;
    line-height: 130%;
    color: theme('primary');
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: 0;
  }

  p {
    font-style: normal;
    font-weight: 700;
    font-size: 1.4rem;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    line-height: 1;
    color: #333333;
    margin-top: 0.5rem;
    margin-bottom: 0;

    @include breakpoint(sm) {
      font-size: 2.4rem;
    }
  }
}

.profile-stat_icon {
  margin: auto 0;

  img {
    max-height: 60px;

    @include breakpoint(sm) {
      max-height: 50px;
    }

    @include breakpoint(md) {
      height: 100%;
      max-width: 76px;
      max-height: 76px;
    }

    @include breakpoint(1280) {
      max-height: 50px;
    }

    @include breakpoint(1420) {
      max-height: 76px;
    }
  }
}

.profile-stats-col {
  padding-left: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  @include breakpoint(0, sm) {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    border-right: none;
  }

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &:nth-child(2),
  &:nth-child(4),
  &:nth-child(6) {
    border-right: none;
  }

  @include breakpoint(lg) {
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    &:nth-child(2),
    &:nth-child(4) {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    &:nth-child(3),
    &:nth-child(6) {
      border-right: none;
    }

    &:nth-child(4) {
      border-bottom: none;
    }
  }
}

.profile-stat_dual {
  display: flex;
}

.profile-stat_tight {
  padding: 0;
  text-align: center;

  p {
    font-size: 0.9rem;
    line-height: 1.5;
    margin-top: 0;

    @include breakpoint(sm) {
      font-size: 1.2rem;
    }

    @include breakpoint(md) {
      font-size: 1.4rem;
    }

    @include breakpoint(1280) {
      font-size: 1rem;
    }

    @include breakpoint(1480) {
      font-size: 1.4rem;
    }
  }
}

.profile-stat-divider {
  width: 2px;
  border-radius: 2px;
  height: 40px;
  margin: 5px;
  background: #e7e7e7;

  @include breakpoint(1420) {
    margin: 5px 10px;
  }
}

.profile-sectionheader {
  width: 100%;
  text-align: center;
  font-weight: 700;
  color: theme('primary');
  margin-top: 2rem;
}

.profile-banner {
  width: 100%;
  height: 90px;
  object-fit: cover;

  @include breakpoint(md) {
    height: 140px;
  }
}

.profile-badges {
  margin: 0 0.75rem;

  @include breakpoint(sm) {
    margin: 0 2rem;
  }
}

.badge-container {
  width: 50%;
  padding: 0 15px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 10px;

  @include breakpoint(sm) {
    width: 33.33%;
  }

  @include breakpoint(lg) {
    width: 20%;
  }

  @include breakpoint(1200) {
    padding: 0 7.5px;
  }

  @include breakpoint(1700) {
    padding: 0 15px;
  }

  p {
    margin-top: 0.5rem;
    font-size: 9px;

    @include breakpoint(md) {
      font-size: 12px;
    }

    @include breakpoint(lg) {
      font-size: 10px;
    }

    @include breakpoint(1756) {
      font-size: 12px;
    }
  }
}

.logout-button {
  color: theme('primary');
  border-radius: 1rem;
  border: 1px solid theme('primary');
  padding: 0.3rem 0.6rem;
  cursor: pointer;
  font-size: 0.7rem;
  font-weight: 700;
  text-transform: uppercase;

  &:hover {
    color: #fff;
    background-color: theme('primary');
  }
}

.btn-leaderboard {
  @include breakpoint(0, sm) {
    font-size: 0.8rem;

    .btn-sm {
      padding: 10px 15px;
    }
  }

  .btn-sm {
    padding: 16px 18px;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.02em;

    &:hover {
      color: #000;
    }
  }
}

.btn-leaderboard:not(:disabled):not(.disabled):hover {
  svg path {
    fill: theme('primary');
  }
}

.profile-links {
  gap: 5px;
}

.profile-image-container {
  width: 100%;
  padding: 1.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .profile-pic {
    border: 3px solid theme('primary');
    cursor: pointer;
    height: 5rem;
    width: 5rem;
    margin-top: -4.25rem;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(md) {
      height: 6rem;
      width: 6rem;
    }

    img {
      image-rendering: auto;
    }
  }
}

.change-profile-link {
  color: #000;
  padding: 0.3rem 0.6rem;
  cursor: pointer;
  border: 1px solid theme('primary');
  color: theme('primary');
  border-radius: 1rem;
  font-size: 0.7rem;
  font-weight: 700;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
    color: #fff;
    background-color: theme('primary');
  }
}

.selection-row {
  margin-top: 1rem;
  padding-right: 0;
  padding-left: 0;

  @include breakpoint(md) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  [class*='col-'] {
    padding-left: 7.5px;
    padding-right: 7.5px;
    margin-bottom: 0.75rem;
    text-align: center;
  }

  img {
    cursor: pointer;
    image-rendering: auto;
  }

  .selected {
    outline: 3px solid theme('primary');
    border-radius: 50%;
    border: 1px solid #fff;
  }
}

.modal-confirm {
  max-width: 360px;
  font-size: 26px;
}
