@import '../../../styles/utilities/variables.scss';
@import '../../../styles/utilities/mixins.scss';
@import '../../../styles/utilities/breakpoints.scss';

.countdown-timer {
  display: flex;
  flex-direction: column;

  @include breakpoint(0, xs) {
    align-items: flex-end;
    margin: 0 auto;
  }

  @include breakpoint(sm, sm) {
    align-items: center;
  }

  @include breakpoint(md, lg) {
    align-items: flex-end;
  }

  @include breakpoint(xl, 0) {
    align-items: center;
  }

  p {
    margin-bottom: 0;
    text-align: center;
    font-weight: $font-weight-bold;
  }

  .countdown-caption {
    font-size: 10px;
    text-transform: uppercase;
    color: theme('link');
  }

  & > .countdown-caption {
    @include breakpoint(0, xs) {
      display: none;
    }

    @include breakpoint(md, lg) {
      display: none;
    }
  }

  .countdown-sections {
    display: flex;
    align-items: center;

    & > .countdown-caption {
      @include breakpoint(0, xs) {
        margin-right: 8px;
      }

      @include breakpoint(sm, sm) {
        display: none;
      }

      @include breakpoint(md, lg) {
        margin-right: 8px;
      }

      @include breakpoint(xl, 0) {
        display: none;
      }
    }

    .countdown-section-content-container {
      width: 34px;

      &.countdown-section-content-container-background {
        background: theme('link');
        border-radius: 2px;

        p {
          color: $white;
        }
      }

      &:not(.countdown-section-content-container-background) {
        p {
          font-size: 6px;
          text-transform: uppercase;
          color: theme('link');
        }

        &:nth-of-type(n + 2) {
          margin-left: 6px;
        }
      }
    }

    .countdown-separator {
      text-align: center;
      width: 6px;

      p {
        color: theme('link');
      }
    }
  }
}
