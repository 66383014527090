::-moz-selection {
  background: theme('primary');
  color: $white;
}

::selection {
  background: theme('primary');
  color: $white;
}

p {
  line-height: 150%;
  font-family: $albra;
  color: $text;

  @media (max-width: $bp-xs-max) {
    font-size: 14px;
  }
}

h1 {
  font-family: $albra;
  color: theme('primary');
  font-weight: $font-weight-bold;

  @include breakpoint(sm, sm) {
    font-size: 1.8rem;
  }

  @include breakpoint(md, lg) {
    font-size: 2rem;
  }

  @media (min-width: 1440px) {
    font-size: 2.1rem;
  }
}

h2 {
  font-family: $albra;
  color: $text;
  font-weight: $font-weight-book;
  line-height: 160%;

  @include breakpoint(0, sm) {
    font-size: 1.2rem;
  }

  @include breakpoint(md, md) {
    font-size: 1.4rem;
  }

  @include breakpoint(lg, lg) {
    font-size: 1.6rem;
  }

  @media (min-width: 1440px) {
    font-size: 26px;
  }
}

h3 {
  font-family: $albra;
  color: theme('link');
  font-weight: $font-weight-bold;
  letter-spacing: 0.03em;

  @include breakpoint(0, xs) {
    font-size: 1.4rem;
  }
}

h4 {
  font-family: $albra;
  color: $text;
  font-weight: $font-weight-book;
  line-height: 150%;

  @media (max-width: $bp-xs-max) {
    font-size: 1.1rem;
  }
}

h5 {
  font-family: $albra;
  color: $text;
  font-weight: $font-weight-book;
  line-height: 150%;

  @media (max-width: $bp-xs-max) {
    font-size: 1.1rem;
  }
}

h6 {
  font-family: $albra;
  letter-spacing: 0.11em;
  text-transform: uppercase;
  color: $text;
}

.copy-desktop {
  @media (max-width: $bp-xs-max) {
    display: none;
  }
}

a {
  font-family: $albra;
  transition: all 300ms ease-in;
}

a:hover {
  text-decoration: none;
}

.centered {
  text-align: center !important;
}

.text-medium {
  font-weight: $font-weight-medium;
}

.regular {
  font-weight: $font-weight-book;
}

.bold {
  font-weight: $font-weight-bold;
}

.text-black {
  font-weight: $font-weight-black;
}

.link {
  color: theme('link');
}

.light {
  color: $text-light;
}

.red {
  color: theme('link');
}

.small {
  font-size: 1rem;
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

.gold {
  color: $ctc-gold;
}

a {
  color: theme('link');

  &:hover {
    @media (hover: hover) {
      color: theme('linkHover');
    }

    @media (hover: none) {
      color: theme('link');
    }
  }
}
