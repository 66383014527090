.video-js {
  position: relative;

  .vjs-big-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50%;

    .vjs-icon-placeholder {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 100%;
      height: 100%;

      @media (max-width: $bp-md-max) {
        width: 60px;
        font-size: 45px;
        line-height: 1;
      }

      @media (min-width: $bp-lg-min) {
        width: 80px;
        font-size: 60px;
        line-height: 1.1;
      }

      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        height: auto;
      }
    }

    @media (max-width: $bp-md-max) {
      width: 80px;
      height: 80px;
      border: 4px solid #ffffff;
    }

    @media (min-width: $bp-lg-min) {
      width: 120px;
      height: 120px;
      border: 7px solid #ffffff;
    }
  }
}


.iframe-16x9-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}